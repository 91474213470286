
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
    "id": 140913,
    "title": {
        "english": "Antonovsky’s (1987) Model of Health Systems after Moos (1985)",
        "svenska": "Antonovsky’s (1987) Model of Health Systems after Moos (1985)"
    },
    "authors": [
        {
            "name": "Aaron Antonovsky"
        }
    ],
    "reference_title": "Unraveling the Mystery of Health: How People Manage Stress and Stay Well",
    "reference_year": "1987",
    "components": {
        "the-personal-system": {
            "id": 140914,
            "title": {
                "english": "The Personal System",
                "svenska": ""
            },
            "description": {
                "english": "",
                "svenska": ""
            },
            "level": 0,
            "parents": [
                140913
            ],
            "components": {
                "sociodemographic-factors": {
                    "id": 140915,
                    "title": {
                        "english": "Sociodemographic Factors",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 0,
                    "parents": [
                        140914
                    ],
                    "components": {}
                },
                "selfconcept": {
                    "id": 140916,
                    "title": {
                        "english": "Self-concept",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 0,
                    "parents": [
                        140914
                    ],
                    "components": {}
                },
                "health-status": {
                    "id": 140917,
                    "title": {
                        "english": "Health Status",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 0,
                    "parents": [
                        140914
                    ],
                    "components": {}
                },
                "functioning-factors": {
                    "id": 140918,
                    "title": {
                        "english": "Functioning Factors",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 0,
                    "parents": [
                        140914
                    ],
                    "components": {}
                }
            }
        },
        "the-environmental-system": {
            "id": 140919,
            "title": {
                "english": "The Environmental System",
                "svenska": ""
            },
            "description": {
                "english": "",
                "svenska": ""
            },
            "level": 0,
            "parents": [
                140913
            ],
            "components": {
                "physical-environment": {
                    "id": 140920,
                    "title": {
                        "english": "Physical Environment",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 0,
                    "parents": [
                        140919
                    ],
                    "components": {}
                },
                "social-policy": {
                    "id": 140921,
                    "title": {
                        "english": "Social Policy",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 0,
                    "parents": [
                        140919
                    ],
                    "components": {}
                },
                "suprapersonal-factors": {
                    "id": 140922,
                    "title": {
                        "english": "Suprapersonal Factors",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 0,
                    "parents": [
                        140919
                    ],
                    "components": {}
                },
                "social-climate-factors": {
                    "id": 140923,
                    "title": {
                        "english": "Social Climate Factors",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 0,
                    "parents": [
                        140919
                    ],
                    "components": {}
                }
            }
        }
    },
    "level": 0
}