
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
    "id": 141012,
    "title": {
        "english": "Resmini and Rosati’s (2017) Model of Information Seeking Behaviour",
        "svenska": "Resmini and Rosati’s (2017) Model of Information Seeking Behaviour"
    },
    "authors": [
        {
            "name": "Andrea Resmini"
        },
        {
            "name": "Luca Rosati"
        }
    ],
    "reference_title": "Pervasive Information Architecture",
    "reference_year": "2017",
    "components": {
        "spiritual": {
            "id": 141013,
            "title": {
                "english": "Spiritual",
                "svenska": ""
            },
            "description": {
                "english": "(religion, philosophy, quest for meaning)",
                "svenska": ""
            },
            "level": 0,
            "parents": [
                141012
            ],
            "components": {}
        },
        "aesthetic": {
            "id": 141014,
            "title": {
                "english": "Aesthetic",
                "svenska": ""
            },
            "description": {
                "english": "(arts and literature)",
                "svenska": ""
            },
            "level": 0,
            "parents": [
                141012
            ],
            "components": {}
        },
        "cognitive--conative--affective": {
            "id": 141015,
            "title": {
                "english": "Cognitive / Conative / Affective",
                "svenska": ""
            },
            "description": {
                "english": "(psychology)",
                "svenska": ""
            },
            "level": 0,
            "parents": [
                141012
            ],
            "components": {}
        },
        "social-and-historical": {
            "id": 141016,
            "title": {
                "english": "Social And Historical",
                "svenska": ""
            },
            "description": {
                "english": "(social sciences)",
                "svenska": ""
            },
            "level": 0,
            "parents": [
                141012
            ],
            "components": {}
        },
        "anthropological": {
            "id": 141017,
            "title": {
                "english": "Anthropological",
                "svenska": ""
            },
            "description": {
                "english": "(physical and cultural)",
                "svenska": ""
            },
            "level": 0,
            "parents": [
                141012
            ],
            "components": {}
        },
        "biological": {
            "id": 141018,
            "title": {
                "english": "Biological",
                "svenska": ""
            },
            "description": {
                "english": "(genetics and ethology)",
                "svenska": ""
            },
            "level": 0,
            "parents": [
                141012
            ],
            "components": {}
        },
        "chemical": {
            "id": 141019,
            "title": {
                "english": "Chemical",
                "svenska": ""
            },
            "description": {
                "english": "",
                "svenska": ""
            },
            "level": 0,
            "parents": [
                141012
            ],
            "components": {}
        },
        "physical": {
            "id": 141020,
            "title": {
                "english": "Physical",
                "svenska": ""
            },
            "description": {
                "english": "",
                "svenska": ""
            },
            "level": 0,
            "parents": [
                141012
            ],
            "components": {}
        },
        "geological": {
            "id": 141021,
            "title": {
                "english": "Geological",
                "svenska": ""
            },
            "description": {
                "english": "",
                "svenska": ""
            },
            "level": 0,
            "parents": [
                141012
            ],
            "components": {}
        },
        "astronomical": {
            "id": 141022,
            "title": {
                "english": "Astronomical",
                "svenska": ""
            },
            "description": {
                "english": "",
                "svenska": ""
            },
            "level": 0,
            "parents": [
                141012
            ],
            "components": {}
        }
    },
    "level": 0
}