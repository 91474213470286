
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
    "id": 137173,
    "title": {
		"english": "Kielhofner’s (2008) Model of Human Occupation",
		"svenska": "Kielhofner’s (2008) Model of Human Occupation"
	},
    "authors": [
        {
            "name": "Eva Norrby"
        },
        {
            "name": "Iréne Linddahl"
        }
    ],
    "reference_title": "Dialogue about work ability (DWA)",
    "reference_year": "2020",
    "components": {
        "occupational-participation": {
            "id": 137174,
            "title": {
                "english": "Occupational Participation",
                "svenska": ""
            },
            "description": {
                "english": "Refers to a person’s involvement and psychosocial well-being, for example at a workplace.",
                "svenska": ""
            },
            "level": 0,
            "parents": [
                137173
            ],
            "components": {
                "involvement--engagement": {
                    "id": 137175,
                    "title": {
                        "english": "Involvement / Engagement",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 0,
                    "parents": [
                        137174
                    ],
                    "components": {}
                },
                "psychosocial-wellbeing": {
                    "id": 137176,
                    "title": {
                        "english": "Psychosocial Well-being",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 0,
                    "parents": [
                        137174
                    ],
                    "components": {}
                }
            }
        },
        "occupational-performance": {
            "id": 137177,
            "title": {
                "english": "Occupational Performance",
                "svenska": ""
            },
            "description": {
                "english": "",
                "svenska": ""
            },
            "level": 0,
            "parents": [
                137173
            ],
            "components": {
                "volition": {
                    "id": 137178,
                    "title": {
                        "english": "Volition",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 0,
                    "parents": [
                        137177
                    ],
                    "components": {}
                },
                "routinerelated-characteristics": {
                    "id": 137179,
                    "title": {
                        "english": "Routine-related Characteristics",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 0,
                    "parents": [
                        137177
                    ],
                    "components": {}
                },
                "physical-ability": {
                    "id": 137180,
                    "title": {
                        "english": "Physical Ability",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 0,
                    "parents": [
                        137177
                    ],
                    "components": {}
                },
                "mental-ability": {
                    "id": 137181,
                    "title": {
                        "english": "Mental Ability",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 0,
                    "parents": [
                        137177
                    ],
                    "components": {}
                },
                "external-physical-environment": {
                    "id": 137182,
                    "title": {
                        "english": "External Physical Environment",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 0,
                    "parents": [
                        137177
                    ],
                    "components": {}
                },
                "external-sociocultural-environment": {
                    "id": 137183,
                    "title": {
                        "english": "External Socio-cultural Environment",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 0,
                    "parents": [
                        137177
                    ],
                    "components": {}
                }
            }
        },
        "occupational-skills": {
            "id": 137184,
            "title": {
                "english": "Occupational Skills",
                "svenska": ""
            },
            "description": {
                "english": "Refers to observable skills.",
                "svenska": ""
            },
            "level": 0,
            "parents": [
                137173
            ],
            "components": {
                "motor-skills": {
                    "id": 137185,
                    "title": {
                        "english": "Motor Skills",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 0,
                    "parents": [
                        137184
                    ],
                    "components": {}
                },
                "process-skills": {
                    "id": 137186,
                    "title": {
                        "english": "Process Skills",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 0,
                    "parents": [
                        137184
                    ],
                    "components": {}
                },
                "communication-skills": {
                    "id": 137187,
                    "title": {
                        "english": "Communication Skills",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 0,
                    "parents": [
                        137184
                    ],
                    "components": {}
                }
            }
        }
    },
    "level": 0
}