
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
	"person": {
		"title": {
			"english": "Gender",
			"svenska": "Kön"
		},
		"definition": {
			"english": "",
			"svenska": ""
		},
		"components": {},
		"value" : "(Male | Female | Neither)",
		"comments": "This is referred to as 'person' in the original code, so presumed to be gender identity."
	},
	"age": {
		"title": {
			"english": "Age",
			"svenska": "Älder"
		},
		"definition": {
			"english": "",
			"svenska": ""
		},
		"components": {},
		"value" : "number",
		"comments": "In original TPG this is marked as 'age at start'."
	},
	"children": {
		"title": {
			"english": "Children",
			"svenska": "Barn"
		},
		"definition": {
			"english": "",
			"svenska": ""
		},
		"components": {
			"total-children": {
				"title": {
					"english": "Total Children",
					"svenska": "Totalt barn"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"comments": ""
			},
			"children-in-care": {
				"title": {
					"english": "Children in care",
					"svenska": "Barn omhändertagna"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"comments": ""
			}
		},
		"comments": "In original TPG this is marked as 'age at start'."
	},
	"drugs": {
		"title": {
			"english": "Drug User",
			"svenska": "Narkotikaanvändare"
		},
		"definition": {
			"english": "",
			"svenska": ""
		},
		"value" : "boolean",
		"components": {},
		"comments": "In original TPG this is marked as just 'drugs', so assumed to be history of recreational drug use, but obviously this could be something else. Prescribed clinical drugs etc. Would be good to establish over what time period, or where in the rehabilitation process this assessment is made, or could be revised. At what point does recreational drug use - if that is what this is, become noteworthy / on the users record."
	},
	"dyslexia": {
		"title": {
			"english": "Dyslexia",
			"svenska": "Dyslexi"
		},
		"definition": {
			"english": "",
			"svenska": ""
		},
		"components": {},
		"value" : "boolean",
		"comments": "Unclear what assessment protocol is used for this, or whether it is taken from clinical records. Is assessing for this something that is done as a matter of course, even if not currently diagnosed. Should it be?"
	},
	"social-connectedness": {
		"title": {
			"english": "Social Connectedness",
			"svenska": "Social anknytning"
		},
		"definition": {
			"english": "",
			"svenska": ""
		},
		"components": {},
		"value" : "unknown",
		"comments": "Unclear what assessment protocol is used for this, or whether it is taken from clinical records. Is assessing for this something that is done as a matter of course, even if not currently diagnosed. Should it be?"
	},
	"bullying": {
		"title": {
			"english": "Bullying",
			"svenska": "Mobbning"
		},
		"definition": {
			"english": "",
			"svenska": ""
		},
		"components": {},
		"value" : "boolean",
		"comments": "Assessment protocol and variables stored are unclear, could be boolean, do they consider themselves to have been bullied - as a marker of other challenges around social connectedness, but again, if we are assessing for autism, and social connectedness using other established protocols, do we need this as a seperate variable. Could be really good to develop a 'recovery from workplace/school bullying' programme though - and track the efficacy of this."
	},
	"employed-status" : {
		"title": {
			"english": "Employed Status",
			"svenska": "Anställningsstatus"
		},
		"definition": {
			"english": "",
			"svenska": ""
		},
		"components": {
			"active-job-seeker" : {
				"title": {
					"english": "Actively Job Seeking",
					"svenska": "Aktivt jobbsökande"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "string",
				"comments":""
			},
			"passive-job-seeker" : {
				"title": {
					"english": "Passively Job Seeking",
					"svenska": "Passivt jobbsökande"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "string",
				"comments":""
			},
			"employed" : {
				"title": {
					"english": "Employed",
					"svenska": "Anställd"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {
					"short-term-employed" : {
						"title": {
							"english": "Short term employed",
							"svenska": "Korttidsanställd"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {},
						"value" : "string",
						"comments":""
					},
					"permanent-employed" : {
						"title": {
							"english": "Permanently employed",
							"svenska": "Permanentanställd"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {},
						"value" : "string",
						"comments":""
					}
				},
				"value" : "string",
				"comments":""
			},
			"in-education" : {
				"title": {
					"english": "In Education or Training",
					"svenska": "I utbildning eller träning"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "string",
				"comments":""
			},
			"apprentice" : {
				"title": {
					"english": "Apprentice",
					"svenska": "Lärling"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "string",
				"comments":""
			},
			"long-term-sick" : {
				"title": {
					"english": "Long-term Sick",
					"svenska": "Sjukskriven"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "string",
				"comments":""
			},
			"retired" : {
				"title": {
					"english": "Retired",
					"svenska": "På pension"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "string",
				"comments":""
			},
			"unemployed" : {
				"title": {
					"english": "Unemployed",
					"svenska": "Arbetslös"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "string",
				"comments":""
			},
			"asylum-seeker" : {
				"title": {
					"english": "Asylum seeker",
					"svenska": "Asylsökande"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "string",
				"comments":""
			}
		},
		"value" : "derived"
	},
	"rehab-status": {
		"title": {
			"english": "Rehabilitation Status",
			"svenska": "Rehabiliteringsstatus"
		},
		"definition": {
			"english": "",
			"svenska": ""
		},
		"components": {
			"rehab-before": {
				"title": {
					"english": "Rehabilitation Before",
					"svenska": "Rehabilitering innan"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {

				},
				"value" : "unknown",
				"comments": ""
			},
			"rehab-duration": {
				"title": {
					"english": "Rehabilitation During",
					"svenska": "Rehabilitering under"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {

				},
				"value" : "unknown",
				"comments": ""
			},
			"rehab-after": {
				"title": {
					"english": "Rehabilitation After",
					"svenska": "Rehabilitering Efter"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {

				},
				"value" : "unknown",
				"comments": ""
			}
		},
		"value" : "unknown",
		"comments": "This is perhaps a bit overlapping, conceptually with the employment status, but, in tpg / JANUS data it is a seperate metric, and, as indicated in this model, also includes the slightly opaque 'rehab_before', 'rehab_after' and 'current_duration'"
	},
	"physical": {
		"title": {
			"english": "Physical Health",
			"svenska": "Fysisk hälsa"
		},
		"definition": {
			"english": "",
			"svenska": ""
		},
		"components": {},
		"value" : "number",
		"comments": ""
	},
	"psychological": {
		"title": {
			"english": "Psychological Health",
			"svenska": "Psykologisk hälsa"
		},
		"definition": {
			"english": "",
			"svenska": ""
		},
		"components": {},
		"value" : "number",
		"comments": ""
	},
	"social": {
		"title": {
			"english": "Social Health",
			"svenska": "Social anknytning"
		},
		"definition": {
			"english": "",
			"svenska": ""
		},
		"components": {},
		"value" : "number",
		"comments": ""
	},
	"financial": {
		"title": {
			"english": "Financial Health",
			"svenska": "Finansiell hälsa"
		},
		"definition": {
			"english": "",
			"svenska": ""
		},
		"components": {},
		"value" : "number",
		"comments": ""
	},
	"self-reflection": {
		"title": {
			"english": "Self Reflection",
			"svenska": "Självreflektion"
		},
		"definition": {
			"english": "",
			"svenska": ""
		},
		"components": {},
		"value" : "number",
		"comments": ""
	},
	"self-discipline": {
		"title": {
			"english": "Self Discipline",
			"svenska": "Självdisciplin"
		},
		"definition": {
			"english": "",
			"svenska": ""
		},
		"components": {},
		"value" : "number",
		"comments": ""
	},
	"creativity": {
		"title": {
			"english": "Creativity",
			"svenska": "Kreativitet"
		},
		"definition": {
			"english": "",
			"svenska": ""
		},
		"components": {},
		"value" : "number",
		"comments": ""
	},
	"technical": {
		"title": {
			"english": "Technical",
			"svenska": "Teknisk"
		},
		"definition": {
			"english": "",
			"svenska": ""
		},
		"components": {},
		"value" : "number",
		"comments": ""
	},
	"time-management": {
		"title": {
			"english": "Time Management",
			"svenska": "Tidshantering"
		},
		"definition": {
			"english": "",
			"svenska": ""
		},
		"components": {},
		"value" : "number",
		"comments": ""
	},
	"empowerment": {
		"title": {
			"english": "Self Empowerment",
			"svenska": "Självbemyndigande"
		},
		"definition": {
			"english": "",
			"svenska": ""
		},
		"components": {},
		"value" : "number",
		"comments": ""
	},
	"resourcefulness": {
		"title": {
			"english": "Resourcefulness",
			"svenska": "Påhittighet"
		},
		"definition": {
			"english": "",
			"svenska": ""
		},
		"components": {},
		"value" : "number",
		"comments": ""
	},
	                                      
	   	          
	   		                                                     
	   		                                                   
	   	  
	   	               
	   		              
	   		             
	   	  
	   	                 
	   	                   
	   	                                                                                                                                                                                                                                                               
	     
	"physical-activity": {
		"title": {
			"english": "Physical Activity",
			"svenska": "Fysisk aktivitet"
		},
		"definition": {
			"english": "",
			"svenska": ""
		},
		"components": {},
		"value" : "number",
		"comments": ""
	},
	"pain": {
		"title": {
			"english": "Pain",
			"svenska": "Värkar"
		},
		"definition": {
			"english": "",
			"svenska": ""
		},
		"components": {},
		"value" : "number",
		"comments": "This is 'verkir' in the Icelandic variables... could be being mistranslated."
	},
	"ht_einb": {
		"title": {
			"english": "Loneliness",
			"svenska": "Ensamhet"
		},
		"definition": {
			"english": "",
			"svenska": ""
		},
		"components": {},
		"value" : "number",
		"comments": "'ht_einb' in the list of data variables, just guessing, but loneliness?"
	},
	"ht_lidan": {
		"title": {
			"english": "Autonomy / Independence",
			"svenska": "Autonomi / Självstandighet"
		},
		"definition": {
			"english": "",
			"svenska": ""
		},
		"components": {},
		"value" : "number",
		"comments": "'ht_lidan' - lið - team(?) an - without(?) - autonomy? / independence / Or something else team-related? Just guessing"
	},
	"ht_svefn": {
		"title": {
			"english": "Sleep",
			"svenska": "Sömn"
		},
		"definition": {
			"english": "",
			"svenska": ""
		},
		"components": {},
		"value" : "number",
		"comments": "Svefn - Sleep"
	},
	"ht_threk": {
		"title": {
			"english": "Endurance / Stamina",
			"svenska": "Uthållighet"
		},
		"definition": {
			"english": "",
			"svenska": ""
		},
		"components": {},
		"value" : "number",
		"comments": "þrek - threk - (Physical) Endurance / Stamina?"
	},
	"ht_samsk": {
		"title": {
			"english": "Collaboration",
			"svenska": "Samarbete"
		},
		"definition": {
			"english": "",
			"svenska": ""
		},
		"components": {},
		"value" : "number",
		"comments": "ht_samsk - Struggling on this one, but maybe samsköpun? - collaboration?"
	},
	"disability": {
		"title": {
			"english": "Disability",
			"svenska": "Funktionshinder"
		},
		"definition": {
			"english": "",
			"svenska": ""
		},
		"components": {
			"icd-count-other": {
				"title": {
					"english": "ICD Count Other",
					"svenska": "ICD Count Other"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {
					"icd-count-m": {
						"title": {
							"english": "ICD Count M - Musculoskeletal system and connective tissue",
							"svenska": "ICD Count M - Musculoskeletal system and connective tissue"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {},
						"value" : "number",
						"comments": "Unclear how this is assessed"
					},
					"icd-count-j": {
						"title": {
							"english": "ICD Count J - Musculoskeletal system and connective tissue",
							"svenska": "ICD Count J - Musculoskeletal system and connective tissue"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {},
						"value" : "number",
						"comments": "Unclear how this is assessed"
					},
					"icd-count-i": {
						"title": {
							"english": "ICD Count I - Musculoskeletal system and connective tissue",
							"svenska": "ICD Count I - Musculoskeletal system and connective tissue"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {},
						"value" : "number",
						"comments": "Unclear how this is assessed"
					},
					"icd-count-f": {
						"title": {
							"english": "ICD Count F - Musculoskeletal system and connective tissue",
							"svenska": "ICD Count F - Musculoskeletal system and connective tissue"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {},
						"value" : "number",
						"comments": "Unclear how this is assessed"
					},
					"icd_count_variation": {
						"title": {
							"english": "ICD Count Variation",
							"svenska": "ICD Count Variation"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {},
						"value" : "number",
						"comments": "Unclear how this is assessed"
					}
				},
				"value" : "number",
				"comments": "Unclear how this is assessed - total ICD codes applicable?"
			}
		},
		"value" : "number",
		"comments": "Unclear how this is assessed?"
	},
	"SPS": {
		"title": {
			"english": "SPS - Social Phobia Score",
			"svenska": "SPS - Social Phobia Score"
		},
		"definition": {
			"english": "",
			"svenska": ""
		},
		"components": {},
		"value" : "number",
		"comments": ""
	},
	"ADHD" : {
		"title": {
			"english": "ADHD",
			"svenska": "ADHD"
		},
		"definition": {
			"english": "",
			"svenska": ""
		},
		"components" : {
			"ADHD-young": {
				"title": {
					"english": "ADHD - Young Person",
					"svenska": "ADHD - Ungdom"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {
					"athygli": {
						"title": {
							"english": "Attention",
							"svenska": "Uppmärksamhet"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {},
						"value" : "number",
						"comments": "athygli"
					},
					"ofvirkni": {
						"title": {
							"english": "Hyperactivity",
							"svenska": "Hyperaktivitet"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {},
						"value" : "number",
						"comments": "ofvirkni"
					}
				},
				"value" : "number",
				"comments": "Young Person ADHD Test Score - Conners-Wells’ Adolescent Self-Report Scale?"
			},
			"ADHD-adult": {
				"title": {
					"english": "ADHD - Adult",
					"svenska": "ADHD - Voksen"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {
					"athygli": {
						"title": {
							"english": "Attention",
							"svenska": "Uppmärksamhet"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {},
						"value" : "number",
						"comments": "athygli"
					},
					"ofvirkni": {
						"title": {
							"english": "Hyperactivity",
							"svenska": "Hyperaktivitet"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {},
						"value" : "number",
						"comments": "ofvirkni"
					}
				},
				"value" : "number",
				"comments": "Adult ADHD Test Score - Conners-Wells' Adolescent Self-Report Scale?"
			}
		},
		"value" : "number",
		"comments": ""
	},
	"dass": {
		"title": {
			"english": "DASS - Depression, Anxiety, Stress Score",
			"svenska": "DASS - Depression, ångest, stresspoäng"
		},
		"definition": {
			"english": "",
			"svenska": ""
		},
		"components": {
			"depression": {
				"title": {
					"english": "Depression",
					"svenska": "Depression"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "number",
				"comments": ""
			},
			"anxiety": {
				"title": {
					"english": "Anxiety",
					"svenska": "Ångest"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "number",
				"comments": ""
			},
			"stress": {
				"title": {
					"english": "Stress",
					"svenska": "Stress"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "number",
				"comments": ""
			}
		},
		"value" : "number",
		"comments": ""
	},
	"sias": {
		"title": {
			"english": "SIAS - Social Interaction Anxiety Score",
			"svenska": "SIAS - Social Interaktion Ångest Skala"
		},
		"definition": {
			"english": "",
			"svenska": ""
		},
		"components": {},
		"value" : "number",
		"comments": "Social Interaction Anxiety Score"
	},
	"dsim": {
		"title": {
			"english": "DSIM - Autism Assessment Score",
			"svenska": "DSIM - Autismbedömningsresultat"
		},
		"definition": {
			"english": "",
			"svenska": ""
		},
		"components": {},
		"value" : "number",
		"comments": "DSIM - Autism Assessment Score"
	}
}	