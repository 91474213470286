
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
    "reference_year": "2016",
    "id": 84509,
    "title": {
        "english": "Schultz’s Model of Work Based Disability",
        "svenska": "Schultz’s Model of Work Based Disability"
    },
    "components": {
		"biomedical": {
			"components": {},
			"id": 84510,
			"title": {
				"english": "Biomedical",
				"svenska": ""
			},
			"level": 0,
			"description": {
				"english": "Tends to consider rehabilitation as recovery from a medical impairment at an individual level.",
				"svenska": ""
			}
		},
		"forensic": {
			"components": {},
			"id": 84510,
			"title": {
				"english": "Forensic",
				"svenska": ""
			},
			"level": 0,
			"description": {
				"english": "Tends to consider the costs and secondary gains of ill-health or exclusion from the job market / society. Looks to assess voracity of claims and minimise the costs and losses.",
				"svenska": ""
			}
		},
		"psychosocial": {
			"components": {
				                   
				   	                 
				   	             
				   	          
				   		                         
				   		             
				   	  
				   	           
				   	                
				   		              
				   		             
				   	 
				     
				               
				   	                 
				   	             
				   	          
				   		                     
				   		             
				   	  
				   	           
				   	                
				   		              
				   		             
				   	 
				     
				                    
				   	                 
				   	             
				   	          
				   		                          
				   		             
				   	  
				   	           
				   	                
				   		              
				   		             
				   	 
				    
			},
			"id": 84511,
			"title": {
				"english": "Psychosocial",
				"svenska": ""
			},
			"level": 0,
			"description": {
				"english": "Takes a primarily individually oriented view of rehabilitation, with a specific focus on rehabilitation as a process of changing individuals attitudes and perceptions to their situation, and ensuring social-connectivity as a key component of rehabilitation.",
				"svenska": ""
			}
		},
		"ecological-case-management": {
			"components": {},
			"id": 84512,
			"title": {
				"english": "Ecological Case Management Approach",
				"svenska": ""
			},
			"level": 0,
			"description": {
				"english": "The Ecological Model approach to Case Management looks at the client as a whole and in context in a social world. The context of the client is considered at three levels; Macro, Meso and Micro.",
				"svenska": ""
			}
		},
		"economic": {
			"components": {},
			"id": 84512,
			"title": {
				"english": "Economic Approach",
				"svenska": ""
			},
			"level": 0,
			"description": {
				"english": "Tends to consider rehabilitation as a problem of incentives at a macro-systemic level. i.e. incentivising training, return to work, or employment.",
				"svenska": ""
			}
		},
		"ergonomic": {
			"components": {},
			"id": 84513,
			"title": {
				"english": "Ergonomic",
				"svenska": ""
			},
			"level": 0,
			"description": {
				"english": "Tends to think of rehabilitation as a process of physical adaptation, in terms of both equipment and job-routines",
				"svenska": ""
			}
		},
		"biopsychosocial": {
			"components": {},
			"id": 84514,
			"title": {
				"english": "Biopsychosocial",
				"svenska": ""
			},
			"level": 0,
			"description": {
				"english": "A model and approach to rehabilitation that seeks to understand, and reshape the interaction between medical, psychosocial, and system-based factors in a persons life, to enable them to achieve employment and a healthy lifestyle of which employment is part.",
				"svenska": ""
			}
		}
    },
    "authors": [
        {
            "name": "Isabel Z. Schultz"
        },
        {
            "name": "Robert J. Gatchel"
        }
    ],
    "reference_title": "Handbook of Return to Work"
}