
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
    "id": 141004,
    "title": {
        "english": "Mazzucato’s (2018) Model of Mission Oriented Innovation",
        "svenska": "Mazzucato’s (2018) Model of Mission Oriented Innovation"
    },
    "authors": [
        {
            "name": "Dan Hill"
        }
    ],
    "reference_title": "Designing missions; Mission-oriented innovation in Sweden",
    "reference_year": "2022",
    "components": {
        "grand-challenge": {
            "id": 141005,
            "title": {
                "english": "Grand Challenge",
                "svenska": ""
            },
            "description": {
                "english": "Political agenda setting and civic engagement",
                "svenska": ""
            },
            "level": 0,
            "parents": [
                141004
            ],
            "components": {}
        },
        "mission": {
            "id": 141006,
            "title": {
                "english": "Mission",
                "svenska": ""
            },
            "description": {
                "english": "Formulation of clearly targetted missions.",
                "svenska": ""
            },
            "level": 0,
            "parents": [
                141004
            ],
            "components": {}
        },
        "crosssectoral-innovation": {
            "id": 141007,
            "title": {
                "english": "Cross-sectoral Innovation",
                "svenska": ""
            },
            "description": {
                "english": "Identification and group of various cross-sectorial actors and partners.",
                "svenska": ""
            },
            "level": 0,
            "parents": [
                141004
            ],
            "components": {}
        },
        "mission-projects": {
            "id": 141008,
            "title": {
                "english": "Mission Projects",
                "svenska": ""
            },
            "description": {
                "english": "Portfolio of projects and bottom-up innovation.",
                "svenska": ""
            },
            "level": 0,
            "parents": [
                141004
            ],
            "components": {}
        }
    },
    "level": 0
}