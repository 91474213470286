
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
	"prior-engagement": {
		"title": {
			"english": "Prior Service Engagement",
			"svenska": "Tidigare Tjänstengagemang"
		},
		"definition": {
			"english": "",
			"svenska": ""
		},
		"components": {
			"tengla": {
				"title": {
					"english": "Previous Contacts",
					"svenska": "Tidigare kontakter"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "number",
				"comments": "tengla"
			},
			"je_before": {
				"title": {
					"english": "je_before?",
					"svenska": "je_before?"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "boolean",
				"comments": "Whether they have engaged with JANUS before?"
			}
		},
		"value" : "category",
		"comments": "These are a list of variables that are currently unclear in the data."
	},
	"person": {
		"title": {
			"english": "Person",
			"svenska": "Person"
		},
		"definition": {
			"english": "",
			"svenska": ""
		},
		"components": {
			"gender": {
				"title": {
					"english": "Gender",
					"svenska": "Kön"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {
					"male": {
						"title": {
							"english": "Male",
							"svenska": "Man"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {},
						"value" : "boolean",
						"comments": "",
					},
					"female": {
						"title": {
							"english": "Female",
							"svenska": "Kvinna"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {},
						"value" : "boolean",
						"comments": ""
					}
				},
				"value" : "category",
				"comments": "This is referred to as 'person' in the original code, so presumed to be gender identity."
			},
			"age": {
				"title": {
					"english": "Age",
					"svenska": "Älder"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "number",
				"comments": "In original TPG this is marked as 'age at start'."
			},
			"pain": {
				"title": {
					"english": "Pain",
					"svenska": "Värkar"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "number",
				"comments": "This is 'verkir' in the Icelandic variables... could be being mistranslated."
			},
			"housing": {
				"title": {
					"english": "Housing",
					"svenska": "Bostadssituation"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {
					"no-house": {
						"title": {
							"english": "None",
							"svenska": "Ingen"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {

						},
						"value" : "boolean",
						"comments": ""
					},
					"rental": {
						"title": {
							"english": "Rental Tenant",
							"svenska": "Hyresrätt"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {

						},
						"value" : "boolean",
						"comments": ""
					},
					"care-home": {
						"title": {
							"english": "Care Home",
							"svenska": "Vårdhem"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {

						},
						"value" : "boolean",
						"comments": ""
					},
					"own-home": {
						"title": {
							"english": "Home Owner",
							"svenska": "Bostadsrätt"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {

						},
						"value" : "boolean",
						"comments": ""
					},
					"shared-accommodation": {
						"title": {
							"english": "Shared Accomodation",
							"svenska": "Gemensamt boende"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {

						},
						"value" : "boolean",
						"comments": ""
					},
					"unknown-home": {
						"title": {
							"english": "Unknown",
							"svenska": "Okänd"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {

						},
						"value" : "boolean",
						"comments": ""
					},
					"homeless": {
						"title": {
							"english": "Homeless",
							"svenska": "Hemlös"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {

						},
						"value" : "boolean",
						"comments": ""
					}
				},
				"value" : "category",
				"comments": ""
			},
			"employed-status" : {
				"title": {
					"english": "Employed Status",
					"svenska": "Anställningsstatus"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {
					"active-job-seeker" : {
						"title": {
							"english": "Actively Job Seeking",
							"svenska": "Aktivt jobbsökande"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {},
						"value" : "boolean",
						"comments":""
					},
					"passive-job-seeker" : {
						"title": {
							"english": "Passively Job Seeking",
							"svenska": "Passivt jobbsökande"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {},
						"value" : "boolean",
						"comments":""
					},
					"employed" : {
						"title": {
							"english": "Employed",
							"svenska": "Anställd"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {
							"short-term-employed" : {
								"title": {
									"english": "Short term employed",
									"svenska": "Korttidsanställd"
								},
								"definition": {
									"english": "",
									"svenska": ""
								},
								"components": {},
								"value" : "boolean",
								"comments":""
							},
							"permanent-employed" : {
								"title": {
									"english": "Permanently employed",
									"svenska": "Permanentanställd"
								},
								"definition": {
									"english": "",
									"svenska": ""
								},
								"components": {},
								"value" : "boolean",
								"comments":""
							}
						},
						"value" : "category",
						"comments":""
					},
					"in-education" : {
						"title": {
							"english": "In Education or Training",
							"svenska": "I utbildning eller träning"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {},
						"value" : "boolean",
						"comments":""
					},
					"apprentice" : {
						"title": {
							"english": "Apprentice",
							"svenska": "Lärling"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {},
						"value" : "boolean",
						"comments":""
					},
					"long-term-sick" : {
						"title": {
							"english": "Long-term Sick",
							"svenska": "Sjukskriven"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {},
						"value" : "boolean",
						"comments":""
					},
					"retired" : {
						"title": {
							"english": "Retired",
							"svenska": "På pension"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {},
						"value" : "boolean",
						"comments":""
					},
					"unemployed" : {
						"title": {
							"english": "Unemployed",
							"svenska": "Arbetslös"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {},
						"value" : "boolean",
						"comments":""
					},
					"asylum-seeker" : {
						"title": {
							"english": "Asylum seeker",
							"svenska": "Asylsökande"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {},
						"value" : "boolean",
						"comments":""
					}
				},
				"value" : "category"
			},
			"rehab-status": {
				"title": {
					"english": "Rehabilitation Status",
					"svenska": "Rehabiliteringsstatus"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {
					"rehab-before": {
						"title": {
							"english": "Rehabilitation Before",
							"svenska": "Rehabilitering innan"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {

						},
						"value" : "unknown",
						"comments": ""
					},
					"rehab-duration": {
						"title": {
							"english": "Rehabilitation During",
							"svenska": "Rehabilitering under"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {

						},
						"value" : "unknown",
						"comments": ""
					},
					"rehab-after": {
						"title": {
							"english": "Rehabilitation After",
							"svenska": "Rehabilitering Efter"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {

						},
						"value" : "unknown",
						"comments": ""
					}
				},
				"value" : "unknown",
				"comments": "This is perhaps a bit overlapping, conceptually with the employment status, but, in tpg / JANUS data it is a separate metric, and, as indicated in this model, also includes the slightly opaque 'rehab_before', 'rehab_after' and 'current_duration'"
			},
			"income": {
				"title": {
					"english": "Income Source",
					"svenska": "Inkomstkälla"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"value" : "multiple",
				"components": {
					"social-benefit": {
						"title": {
							"english": "Social Benefit",
							"svenska": "Social Stöd"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"value" : "boolean",
						"components": {},
						"comments": ""
					},
					"rehab-benefit": {
						"title": {
							"english": "Rehab Benefit",
							"svenska": "Rehab Stöd"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"value" : "boolean",
						"components": {},
						"comments": ""
					},
					"unemployment-benefit": {
						"title": {
							"english": "Unemployment Benefit",
							"svenska": "Akassa"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"value" : "boolean",
						"components": {},
						"comments": ""
					},
					"union-benefit": {
						"title": {
							"english": "Union Benefit",
							"svenska": "Fakliga Akassa"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"value" : "boolean",
						"components": {},
						"comments": ""
					},
					"salary": {
						"title": {
							"english": "Salary",
							"svenska": "Lön"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"value" : "boolean",
						"components": {},
						"comments": ""
					},
					"parental-leave": {
						"title": {
							"english": "Parental Leave",
							"svenska": "Föräldraledig"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"value" : "boolean",
						"components": {},
						"comments": ""
					},
					"disability-benefit": {
						"title": {
							"english": "Disability Benefit",
							"svenska": "Sjukpenning"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"value" : "boolean",
						"components": {},
						"comments": ""
					},
					"housing-benefit": {
						"title": {
							"english": "Housing Benefit",
							"svenska": "Bostadsbidrag"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"value" : "boolean",
						"components": {},
						"comments": ""
					},
					"state-pension": {
						"title": {
							"english": "State Pension",
							"svenska": "Statliga Pension"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"value" : "boolean",
						"components": {},
						"comments": ""
					},
					"private-pension": {
						"title": {
							"english": "Private Pension",
							"svenska": "Privat Pension"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"value" : "boolean",
						"components": {},
						"comments": ""
					},
					"student-loan": {
						"title": {
							"english": "Student Loan",
							"svenska": "Studentlön"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"value" : "boolean",
						"components": {},
						"comments": ""
					},
					"family": {
						"title": {
							"english": "Family Support",
							"svenska": "Familjstöd"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"value" : "boolean",
						"components": {},
						"comments": ""
					},
					"other": {
						"title": {
							"english": "Other",
							"svenska": "Annat"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"value" : "boolean",
						"components": {},
						"comments": ""
					},
					"no-income-data": {
						"title": {
							"english": "No Income",
							"svenska": "Ingen inkomst"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"value" : "boolean",
						"components": {},
						"comments": ""
					}
				},
				"comments": ""
			},
			"drugs": {
				"title": {
					"english": "Drug User",
					"svenska": "Narkotikaanvändare"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"value" : "boolean",
				"components": {},
				"comments": "In original TPG this is marked as just 'drugs', so assumed to be history of recreational drug use, but obviously this could be something else. Prescribed clinical drugs etc. Would be good to establish over what time period, or where in the rehabilitation process this assessment is made, or could be revised. At what point does recreational drug use - if that is what this is, become noteworthy / on the users record."
			}
		},
		"value" : "category",
		"comments": ""
	},
	"life-sitation": {
		"title": {
			"english": "Life Situation",
			"svenska": "Livssituation"
		},
		"definition": {
			"english": "",
			"svenska": ""
		},
		"components": {
			"relationship": {
				"title": {
					"english": "Relationship Status",
					"svenska": "Relation status"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {
					"single": {
						"title": {
							"english": "Single",
							"svenska": "Singel"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {

						},
						"value" : "boolean",
						"comments": ""
					},
					"cohabiting": {
						"title": {
							"english": "Co-habiting",
							"svenska": "Sambo"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {

						},
						"value" : "boolean",
						"comments": ""
					},
					"married": {
						"title": {
							"english": "Married",
							"svenska": "Gift"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {

						},
						"value" : "boolean",
						"comments": ""
					},
					"divorced": {
						"title": {
							"english": "Divorced",
							"svenska": "Skild"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {

						},
						"value" : "boolean",
						"comments": ""
					},
					"widowee": {
						"title": {
							"english": "Widowed / Widower",
							"svenska": "Anka / Ankling"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {

						},
						"value" : "boolean",
						"comments": ""
					}
				},
				"value" : "multiple",
				"comments": ""
			},
			"children": {
				"title": {
					"english": "Children",
					"svenska": "Barn"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {
					"total-children": {
						"title": {
							"english": "Total Children",
							"svenska": "Totalt barn"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {},
						"comments": ""
					},
					"children-in-care": {
						"title": {
							"english": "Children in care",
							"svenska": "Barn omhändertagna"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {},
						"comments": ""
					}
				},
				"comments": "In original TPG this is marked as 'age at start'."
			},
			"disability": {
				"title": {
					"english": "Disability",
					"svenska": "Funktionsnedsättning"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {
					"dyslexia": {
						"title": {
							"english": "Dyslexia",
							"svenska": "Dyslexi"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {},
						"value" : "boolean",
						"comments": "Unclear what assessment protocol is used for this, or whether it is taken from clinical records. Is assessing for this something that is done as a matter of course, even if not currently diagnosed. Should it be?"
					},
					"disability-proportion": {
						"title": {
							"english": "Disability Proportion",
							"svenska": "Funktionshinder Proportion"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {},
						"value" : "number",
						"comments": "Unclear how this is assessed?"
					},
					"icd-count-other": {
						"title": {
							"english": "ICD Count Other",
							"svenska": "ICD Count Other"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {
							"icd-count-m": {
								"title": {
									"english": "ICD Count M - Musculoskeletal system and connective tissue",
									"svenska": "ICD Count M - Musculoskeletal system and connective tissue"
								},
								"definition": {
									"english": "",
									"svenska": ""
								},
								"components": {},
								"value" : "number",
								"comments": "Unclear how this is assessed"
							},
							"icd-count-j": {
								"title": {
									"english": "ICD Count J - Musculoskeletal system and connective tissue",
									"svenska": "ICD Count J - Musculoskeletal system and connective tissue"
								},
								"definition": {
									"english": "",
									"svenska": ""
								},
								"components": {},
								"value" : "number",
								"comments": "Unclear how this is assessed"
							},
							"icd-count-i": {
								"title": {
									"english": "ICD Count I - Musculoskeletal system and connective tissue",
									"svenska": "ICD Count I - Musculoskeletal system and connective tissue"
								},
								"definition": {
									"english": "",
									"svenska": ""
								},
								"components": {},
								"value" : "number",
								"comments": "Unclear how this is assessed"
							},
							"icd-count-f": {
								"title": {
									"english": "ICD Count F - Musculoskeletal system and connective tissue",
									"svenska": "ICD Count F - Musculoskeletal system and connective tissue"
								},
								"definition": {
									"english": "",
									"svenska": ""
								},
								"components": {},
								"value" : "number",
								"comments": "Unclear how this is assessed"
							},
							"icd_count_variation": {
								"title": {
									"english": "ICD Count Variation",
									"svenska": "ICD Count Variation"
								},
								"definition": {
									"english": "",
									"svenska": ""
								},
								"components": {},
								"value" : "number",
								"comments": "Unclear how this is assessed"
							}
						},
						"value" : "number",
						"comments": "Unclear how this is assessed - total ICD codes applicable?"
					}
				},
				"value" : "category",
				"comments": ""
			},
			"bullying": {
				"title": {
					"english": "Bullying",
					"svenska": "Mobbning"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "boolean",
				"comments": "Assessment protocol and variables stored are unclear, could be boolean, do they consider themselves to have been bullied - as a marker of other challenges around social connectedness, but again, if we are assessing for autism, and social connectedness using other established protocols, do we need this as a seperate variable. Could be really good to develop a 'recovery from workplace/school bullying' programme though - and track the efficacy of this."
			},
			"social-connectedness": {
				"title": {
					"english": "Social Connectedness",
					"svenska": "Social anknytning"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "unknown",
				"comments": "Unclear what assessment protocol is used for this, or whether it is taken from clinical records. Is assessing for this something that is done as a matter of course, even if not currently diagnosed. Should it be?"
			}
		},
		"value" : "category",
		"comments": ""
	},
	"service": {
		"title": {
			"english": "TPG Variables",
			"svenska": "TPG Variabler"
		},
		"definition": {
			"english": "",
			"svenska": ""
		},
		"components": {
			"physical": {
				"title": {
					"english": "Physical Health",
					"svenska": "Fysisk hälsa"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "number",
				"comments": ""
			},
			"self-discipline": {
				"title": {
					"english": "Self Discipline",
					"svenska": "Självdisciplin"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "number",
				"comments": ""
			},
			"self-reflection": {
				"title": {
					"english": "Self Reflection",
					"svenska": "Självreflektion"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "number",
				"comments": ""
			},
			"psychological": {
				"title": {
					"english": "Psychological Health",
					"svenska": "Psykologisk hälsa"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "number",
				"comments": ""
			},
			"social-activity": {
				"title": {
					"english": "Social Activity",
					"svenska": "Social aktivitet"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "number",
				"comments": ""
			},
			"financial": {
				"title": {
					"english": "Financial Health",
					"svenska": "Finansiell hälsa"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "number",
				"comments": ""
			},
			"creativity": {
				"title": {
					"english": "Creativity",
					"svenska": "Kreativitet"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "number",
				"comments": ""
			},
			"technical": {
				"title": {
					"english": "Technical",
					"svenska": "Teknisk"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "number",
				"comments": ""
			},
			"time-management": {
				"title": {
					"english": "Time Management",
					"svenska": "Tidshantering"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "number",
				"comments": ""
			},
			"empowerment": {
				"title": {
					"english": "Self Empowerment",
					"svenska": "Självbemyndigande"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "number",
				"comments": ""
			},
			"resourcefulness": {
				"title": {
					"english": "Resourcefulness",
					"svenska": "Påhittighet"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "number",
				"comments": ""
			}
		},
		"value" : "category",
		"comments": ""
	},
	"ht": {
		"title": {
			"english": "HT Variables",
			"svenska": "HT Variabler"
		},
		"definition": {
			"english": "",
			"svenska": ""
		},
		"components": {
			"ht_heils": {
				"title": {
					"english": "Health",
					"svenska": "Hälsa"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "number",
				"comments": "This is 'heils' in the Icelandic variables... could be being mistranslated. General Health, presumably self assessed overall (physical) health, or overall physical and psychological health? But need for clarity in our own future implementations about how this assessment differs from the other variables physical health and psychological health that are also in the patient vector."
			},
			"physical-activity": {
				"title": {
					"english": "Physical Activity",
					"svenska": "Fysisk aktivitet"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "number",
				"comments": ""
			},
			"ht_dep": {
				"title": {
					"english": "Depression",
					"svenska": "Depression"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "number",
				"comments": ""
			},
			"ht_einb": {
				"title": {
					"english": "Loneliness",
					"svenska": "Ensamhet"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "number",
				"comments": "'ht_einb' in the list of data variables, just guessing, but loneliness?"
			},
			"ht_lidan": {
				"title": {
					"english": "Autonomy / Independence",
					"svenska": "Autonomi / Självstandighet"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "number",
				"comments": "'ht_lidan' - lið - team(?) an - without(?) - autonomy? / independence / Or something else team-related? Just guessing"
			},
			"ht_sjalfs": {
				"title": {
					"english": "Self-reflection",
					"svenska": "Ångest"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "number",
				"comments": "Sjálfsagi (self-discipline) or sjalfseing (self-reflection)  - we name both of these as variables in some documentation, yet there is only one variable with 'sjalfs' so unclear which one this is."
			},
			"ht_kvidi": {
				"title": {
					"english": "Anxiety",
					"svenska": "Ångest"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "number",
				"comments": "Kvíði - Anxiety"
			},
			"ht_svefn": {
				"title": {
					"english": "Sleep",
					"svenska": "Sömn"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "number",
				"comments": "Svefn - Sleep"
			},
			"ht_threk": {
				"title": {
					"english": "Endurance / Stamina",
					"svenska": "Uthållighet"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "number",
				"comments": "þrek - threk - (Physical) Endurance / Stamina?"
			},
			"ht_samsk": {
				"title": {
					"english": "Collaboration",
					"svenska": "Samarbete"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "number",
				"comments": "ht_samsk - Struggling on this one, but maybe samsköpun? - collaboration?"
			},
			"ht_fjarh": {
				"title": {
					"english": "Financial Self Competence",
					"svenska": "Finansiell självkompetens"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "number",
				"comments": "ht_samsk - Struggling on this one, but maybe samsköpun? - collaboration?"
			}
		},
		"value" : "category",
		"comments": ""
	},
	"extrinsic-protocols" : {
		"title": {
			"english": "Other Evidence Informed Protocols",
			"svenska": "Andra bevisinformerade protokoll"
		},
		"definition": {
			"english": "",
			"svenska": ""
		},
		"components": {
			"rosenborg": {
				"title": {
					"english": "Rosenborg Self Efficacy Scale",
					"svenska": "Rosenborg Självförmågaskala"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "number",
				"comments": ""
			},
			"sps": {
				"title": {
					"english": "SPS - Social Phobia Score",
					"svenska": "SPS - Social Phobia Score"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "number",
				"comments": ""
			},
			"adhd": {
				"title": {
					"english": "ADHD",
					"svenska": "ADHD"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {
					"adhd-young": {
						"title": {
							"english": "ADHD - Young Person",
							"svenska": "ADHD - Ungdom"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {
							"young-athygli": {
								"title": {
									"english": "Attention",
									"svenska": "Uppmärksamhet"
								},
								"definition": {
									"english": "",
									"svenska": ""
								},
								"components": {},
								"value" : "number",
								"comments": "athygli"
							},
							"young-ofvirkni": {
								"title": {
									"english": "Hyperactivity",
									"svenska": "Hyperaktivitet"
								},
								"definition": {
									"english": "",
									"svenska": ""
								},
								"components": {},
								"value" : "number",
								"comments": "ofvirkni"
							}
						},
						"value" : "number",
						"comments": "Young Person ADHD Test Score - Conners-Wells’ Adolescent Self-Report Scale?"
					},
					"adhd-adult": {
						"title": {
							"english": "ADHD - Adult",
							"svenska": "ADHD - Voksen"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {
							"adult-athygli": {
								"title": {
									"english": "Attention",
									"svenska": "Uppmärksamhet"
								},
								"definition": {
									"english": "",
									"svenska": ""
								},
								"components": {},
								"value" : "number",
								"comments": "athygli"
							},
							"adult-ofvirkni": {
								"title": {
									"english": "Hyperactivity",
									"svenska": "Hyperaktivitet"
								},
								"definition": {
									"english": "",
									"svenska": ""
								},
								"components": {},
								"value" : "number",
								"comments": "ofvirkni"
							}
						},
						"value" : "number",
						"comments": "Adult ADHD Test Score - Conners-Wells' Adolescent Self-Report Scale?"
					}
				},
				"value" : "number",
				"comments": ""
			},
			"dass": {
				"title": {
					"english": "DASS - Depression, Anxiety, Stress Score",
					"svenska": "DASS - Depression, ångest, stresspoäng"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {
					"dass-depression": {
						"title": {
							"english": "Depression",
							"svenska": "Depression"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {},
						"value" : "number",
						"comments": ""
					},
					"dass-anxiety": {
						"title": {
							"english": "Anxiety",
							"svenska": "Ångest"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {},
						"value" : "number",
						"comments": ""
					},
					"dass-stress": {
						"title": {
							"english": "Stress",
							"svenska": "Stress"
						},
						"definition": {
							"english": "",
							"svenska": ""
						},
						"components": {},
						"value" : "number",
						"comments": ""
					}
				},
				"value" : "number",
				"comments": ""
			},
			"sias": {
				"title": {
					"english": "SIAS - Social Interaction Anxiety Score",
					"svenska": "SIAS - Social Interaktion Ångest Skala"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "number",
				"comments": "Social Interaction Anxiety Score"
			},
			"dsim": {
				"title": {
					"english": "DSIM - Autism Assessment Score",
					"svenska": "DSIM - Autismbedömningsresultat"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "number",
				"comments": "DSIM - Autism Assessment Score"
			},
			"organisational-identification" : {
				"title": {
					"english": "Organisational / Job Identification (?)",
					"svenska": "Organisatorisk / jobbidentifiering (?)"
				},
				"definition": {
					"english": "",
					"svenska": ""
				},
				"components": {},
				"value" : "category",
				"comments": "From what I can infer, 'mael' in the variable list could refer to Mael & Ashforth (1992) Organizational Identification Scale? - just guessing, and goodness knows that ae vowel combo could be something Icelandic - 'mael' - measure? something?"
			},
		},
		"value" : "category",
		"comments": "This is a collection of all the other research informed methods I can identify"
	}
}	