import antonovsky_data from "../../../../data-models/work-capacity-models/antonovskys-two-systems-model.jsonc";
import blumberg_data from "../../../../data-models/work-capacity-models/blumberg-and-pringle-job-performance.jsonc";
import bip_original_data from "../../../../data-models/work-capacity-models/bip-original-indicators.jsonc";
import bip_simplifed_data from "../../../../data-models/work-capacity-models/bip-simplified-indicators.jsonc";
import bip_soc_abstraction_data from "../../../../data-models/work-capacity-models/bip-soc-abstracted.jsonc";
import engels from "../../../../data-models/hierarchies/engels-natural-systems.jsonc";
import ekberg from "../../../../data-models/icf/ekberg-classification-of-functionality/ekberg-icf.jsonc";
import fidler_data from "../../../../data-models/work-capacity-models/fidler-work-capacity.jsonc";
import icf_core_rehab_data from "../../../../data-models/icf/escorpizo-icf-core-rehabilitation-set/icf-core-rehabilitation-set.json";
import jafari_data from "../../../../data-models/values/jafari-values.jsonc";
import kielhofner_data from "../../../../data-models/work-capacity-models/kielhofner-moho.jsonc";
import krippendorff from "../../../../data-models/hierarchies/krippendorff-artificiality.jsonc";
import laws_copm_data from "../../../../data-models/work-capacity-models/laws-copm-model-of-occupational-performance.jsonc";
import ludvigsson_data from "../../../../data-models/work-capacity-models/ludvigsson.jsonc";
import melin_data from "../../../../data-models/work-capacity-models/melins-lisat-model.jsonc";
import tengland_data from "../../../../data-models/work-capacity-models/tengland.jsonc";
import tengland_abstracted_data from "../../../../data-models/work-capacity-models/tengland-abstracted.jsonc";
import tengland_soc_abstracted_data from "../../../../data-models/work-capacity-models/tengland-socially-constructed.jsonc";
import sandqvist_data from "../../../../data-models/work-capacity-models/sandqvists-assessment-of-work-performance.jsonc";
import schultz_data from "../../../../data-models/work-capacity-models/schultzs-model-of-paradigms-in-return-to-work.jsonc";
import simplified_tpg from "../../../../data-models/tpg-models/simplified_tpg.jsonc";
import knauf_schultz_data from "../../../../data-models/work-capacity-models/knauf-and-schultz-return-to-work-paradigms.jsonc";
import westerholm_data from "../../../../data-models/work-capacity-models/westerholm-bostedt-arbetesformaga.jsonc";
import copm_data from "../../../../data-models/work-capacity-models/canadian-occupational-performance-manual.jsonc";
import regneringen_data from "../../../../data-models/work-capacity-models/regneringen-arbetesmarknad.jsonc";
import resmini from "../../../../data-models/hierarchies/resmini-information-seeking.jsonc";
import vaekshusets_non_labourmarket_ready from "../../../../data-models/work-capacity-models/vaekshusets-non-labour-market-ready.jsonc";
import vaekshusets_job_readiness from "../../../../data-models/work-capacity-models/vaekshusets-job-readiness.jsonc";
import * as React from 'react';
export default {
  antonovsky_data,
  blumberg_data,
  bip_original_data,
  bip_simplifed_data,
  bip_soc_abstraction_data,
  engels,
  ekberg,
  fidler_data,
  icf_core_rehab_data,
  jafari_data,
  kielhofner_data,
  krippendorff,
  laws_copm_data,
  ludvigsson_data,
  melin_data,
  tengland_data,
  tengland_abstracted_data,
  tengland_soc_abstracted_data,
  sandqvist_data,
  schultz_data,
  simplified_tpg,
  knauf_schultz_data,
  westerholm_data,
  copm_data,
  regneringen_data,
  resmini,
  vaekshusets_non_labourmarket_ready,
  vaekshusets_job_readiness,
  React
};