
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
    "reference_year": "2020",
    "id": 139632,
    "title": {
        "english": "Tengland’s (2006) Model of Ability to Work",
        "svenska": "Tengland’s (2006) Model of Ability to Work"
    },
    "components": {
        "health": {
            "components": {},
            "id": 139633,
            "title": {
                "english": "Health",
                "svenska": "Hälsa"
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "competence": {
            "components": {},
            "id": 139635,
            "title": {
                "english": "Competence",
                "svenska": "Kompetens"
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "work-environment": {
            "components": {},
            "id": 139639,
            "title": {
                "english": "Work Environment",
                "svenska": "Arbetesmiljö"
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "motivation": {
            "components": {},
            "id": 139636,
            "title": {
                "english": "Motivation",
                "svenska": "Motivation"
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "illness": {
            "components": {},
            "id": 139634,
            "title": {
                "english": "Illness",
                "svenska": "Sjukdom"
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "volition": {
            "components": {},
            "id": 139637,
            "title": {
                "english": "Volition",
                "svenska": "Vilja"
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "nature-of-the-work": {
            "components": {},
            "id": 139638,
            "title": {
                "english": "Nature Of The Work",
                "svenska": "Arbetets karaktär"
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        }
    },
    "authors": [
        {
            "name": "Eva Norrby"
        },
        {
            "name": "Iréne Linddahl"
        }
    ],
    "reference_title": "Dialogue about work ability (DWA)"
}