
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
    "id": 140884,
    "title": {
        "english": "Westerholm and Bostedt (2004)’s Model of Work Ability",
        "svenska": "Westerholm and Bostedt (2004)’s Model of Work Ability"
    },
    "authors": [
        {
            "name": "Per-Anders Tengland"
        }
    ],
    "reference_title": "Begreppet arbetsförmåga",
    "reference_year": "2006",
    "components": {
        "individual-resources": {
            "id": 140897,
            "title": {
                "english": "Individual Resources",
                "svenska": ""
            },
            "description": {
                "english": "",
                "svenska": ""
            },
            "level": 1,
            "parents": [
                140884
            ],
            "components": {
                "health-and-functional-capacity": {
                    "id": 140885,
                    "title": {
                        "english": "Health and Functional Capacity",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 2,
                    "parents": [
                        140897
                    ],
                    "components": {
                        "physical": {
                            "id": 140886,
                            "title": {
                                "english": "Physical",
                                "svenska": ""
                            },
                            "description": {
                                "english": "",
                                "svenska": ""
                            },
                            "level": 3,
                            "parents": [
                                140885
                            ],
                            "components": {}
                        },
                        "psychological": {
                            "id": 140887,
                            "title": {
                                "english": "Psychological",
                                "svenska": ""
                            },
                            "description": {
                                "english": "",
                                "svenska": ""
                            },
                            "level": 3,
                            "parents": [
                                140885
                            ],
                            "components": {}
                        },
                        "social": {
                            "id": 140888,
                            "title": {
                                "english": "Social",
                                "svenska": ""
                            },
                            "description": {
                                "english": "",
                                "svenska": ""
                            },
                            "level": 3,
                            "parents": [
                                140885
                            ],
                            "components": {}
                        }
                    }
                },
                "education-and-competence": {
                    "id": 140889,
                    "title": {
                        "english": "Education and Competence",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 2,
                    "parents": [
                        140897
                    ],
                    "components": {}
                },
                "values-and-attitude": {
                    "id": 140890,
                    "title": {
                        "english": "Values and Attitude",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 2,
                    "parents": [
                        140897
                    ],
                    "components": {}
                },
                "motivation": {
                    "id": 140891,
                    "title": {
                        "english": "Motivation",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 2,
                    "parents": [
                        140897
                    ],
                    "components": {}
                }
            }
        },
        "extrinsic-factors": {
            "id": 140898,
            "title": {
                "english": "Extrinsic Factors",
                "svenska": ""
            },
            "description": {
                "english": "",
                "svenska": ""
            },
            "level": 1,
            "parents": [
                140884
            ],
            "components": {
                "work-requirements": {
                    "id": 140892,
                    "title": {
                        "english": "Work Requirements",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 2,
                    "parents": [
                        140898
                    ],
                    "components": {
                        "physical-requirements": {
                            "id": 140893,
                            "title": {
                                "english": "Physical Requirements",
                                "svenska": ""
                            },
                            "description": {
                                "english": "",
                                "svenska": ""
                            },
                            "level": 3,
                            "parents": [
                                140892
                            ],
                            "components": {}
                        },
                        "psychological-requirements": {
                            "id": 140894,
                            "title": {
                                "english": "Psychological Requirements",
                                "svenska": ""
                            },
                            "description": {
                                "english": "",
                                "svenska": ""
                            },
                            "level": 3,
                            "parents": [
                                140892
                            ],
                            "components": {}
                        }
                    }
                },
                "organisation-of-work-environment": {
                    "id": 140895,
                    "title": {
                        "english": "Organisation of Work Environment",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 2,
                    "parents": [
                        140898
                    ],
                    "components": {}
                },
                "leadership": {
                    "id": 140896,
                    "title": {
                        "english": "Leadership",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 2,
                    "parents": [
                        140898
                    ],
                    "components": {}
                },
                "working-environment": {
                    "id": 140899,
                    "title": {
                        "english": "Working Environment",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 2,
                    "parents": [
                        140898
                    ],
                    "components": {}
                }
            }
        }
    },
    "level": 0
}