
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
    "id": 140903,
    "title": {
        "english": "Law et al’s (1997) Canadian Model of Occupational Performance",
        "svenska": "Law et al’s (1997) Canadian Model of Occupational Performance"
    },
    "authors": [
        {
            "name": "Mary Law"
        }
    ],
    "reference_title": "COPM Manual",
    "reference_year": "2020",
    "components": {
        "the-person": {
            "id": 140904,
            "title": {
                "english": "The Person",
                "svenska": ""
            },
            "description": {
                "english": "",
                "svenska": ""
            },
            "level": 0,
            "parents": [
                140903
            ],
            "components": {
                "physical": {
                    "id": 140905,
                    "title": {
                        "english": "Physical",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 0,
                    "parents": [
                        140904
                    ],
                    "components": {}
                },
                "affective": {
                    "id": 140906,
                    "title": {
                        "english": "Affective",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 0,
                    "parents": [
                        140904
                    ],
                    "components": {}
                },
                "cognitive": {
                    "id": 140907,
                    "title": {
                        "english": "Cognitive",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 0,
                    "parents": [
                        140904
                    ],
                    "components": {}
                }
            }
        },
        "the-environment": {
            "id": 140908,
            "title": {
                "english": "The Environment",
                "svenska": ""
            },
            "description": {
                "english": "",
                "svenska": ""
            },
            "level": 0,
            "parents": [
                140903
            ],
            "components": {
                "physical-environment": {
                    "id": 140909,
                    "title": {
                        "english": "Physical Environment",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 0,
                    "parents": [
                        140908
                    ],
                    "components": {}
                },
                "social-environment": {
                    "id": 140910,
                    "title": {
                        "english": "Social Environment",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 0,
                    "parents": [
                        140908
                    ],
                    "components": {}
                },
                "cultural-environment": {
                    "id": 140911,
                    "title": {
                        "english": "Cultural Environment",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 0,
                    "parents": [
                        140908
                    ],
                    "components": {}
                },
                "institutional-environment": {
                    "id": 140912,
                    "title": {
                        "english": "Institutional Environment",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 0,
                    "parents": [
                        140908
                    ],
                    "components": {}
                }
            }
        }
    },
    "level": 0
}