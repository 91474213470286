
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
    "reference_year": "2020",
    "id": 137926,
    "title": {
        "english": "Canadian Occupation Performance Measure Model of Occupation",
        "svenska": "Canadian Occupation Performance Measure Model of Occupation"
    },
    "components": {
        "leisure": {
            "components": {
                "quiet-recreation": {
                    "components": {},
                    "id": 137946,
                    "title": {
                        "english": "Quiet Recreation",
                        "svenska": ""
                    },
                    "level": 0,
                    "description": {
                        "english": "",
                        "svenska": ""
                    }
                },
                "active-recreation": {
                    "components": {},
                    "id": 137947,
                    "title": {
                        "english": "Active Recreation",
                        "svenska": ""
                    },
                    "level": 0,
                    "description": {
                        "english": "",
                        "svenska": ""
                    }
                },
                "socialization": {
                    "components": {},
                    "id": 137948,
                    "title": {
                        "english": "Socialization",
                        "svenska": ""
                    },
                    "level": 0,
                    "description": {
                        "english": "",
                        "svenska": ""
                    }
                }
            },
            "id": 137945,
            "title": {
                "english": "Leisure",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "Leisure includes the occupations performed by an individual when freed from the obligation to be productive (McColl, Law, Doubt, Pollock, & Stewart, 2003; McColl et al., 2014)",
                "svenska": ""
            }
        },
        "productivity": {
            "components": {
                "school": {
                    "components": {},
                    "id": 137943,
                    "title": {
                        "english": "School",
                        "svenska": ""
                    },
                    "level": 0,
                    "description": {
                        "english": "",
                        "svenska": ""
                    }
                },
                "play": {
                    "components": {},
                    "id": 137944,
                    "title": {
                        "english": "Play",
                        "svenska": ""
                    },
                    "level": 0,
                    "description": {
                        "english": "",
                        "svenska": ""
                    }
                },
                "paid-or-unpaid-work": {
                    "components": {},
                    "id": 137941,
                    "title": {
                        "english": "Paid Or Unpaid Work",
                        "svenska": ""
                    },
                    "level": 0,
                    "description": {
                        "english": "",
                        "svenska": ""
                    }
                },
                "household-management": {
                    "components": {},
                    "id": 137942,
                    "title": {
                        "english": "Household Management",
                        "svenska": ""
                    },
                    "level": 0,
                    "description": {
                        "english": "",
                        "svenska": ""
                    }
                }
            },
            "id": 137940,
            "title": {
                "english": "Productivity",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "Productivity includes occupations aimed at earning a living, maintaining home and family, providing service to others and/or developing one's capabilities (McColl et al., 2014)",
                "svenska": ""
            }
        },
        "self-care": {
            "components": {
                "community-management": {
                    "components": {},
                    "id": 137939,
                    "title": {
                        "english": "Community Management",
                        "svenska": ""
                    },
                    "level": 0,
                    "description": {
                        "english": "",
                        "svenska": ""
                    }
                },
                "personal-care": {
                    "components": {},
                    "id": 137937,
                    "title": {
                        "english": "Personal Care",
                        "svenska": ""
                    },
                    "level": 0,
                    "description": {
                        "english": "",
                        "svenska": ""
                    }
                },
                "functional-mobility": {
                    "components": {},
                    "id": 137938,
                    "title": {
                        "english": "Functional Mobility",
                        "svenska": ""
                    },
                    "level": 0,
                    "description": {
                        "english": "",
                        "svenska": ""
                    }
                }
            },
            "id": 137936,
            "title": {
                "english": "Self-care",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "Self-care includes occupations aimed at getting ready for the day and getting around (McColl, Law & Stewart, 2014)",
                "svenska": ""
            }
        }
    },
    "authors": [
        {
            "name": "Mary Law"
        }
    ],
    "reference_title": "Canadian Occupational Performance Measure Manual"
}