
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
    "reference_year": "2020",
    "id": 137164,
    "title": {
        "english": "Swedish National Labour Market Policy’s Concept of Ability to Work",
        "svenska": "Swedish National Labour Market Policy’s Concept of Ability to Work"
    },
    "components": {
        "flexibility": {
            "components": {},
            "id": 137171,
            "title": {
                "english": "Flexibility",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "work-experience": {
            "components": {},
            "id": 137166,
            "title": {
                "english": "Work Experience",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "ability-to-act": {
            "components": {},
            "id": 137172,
            "title": {
                "english": "Ability To Act",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "behaviour": {
            "components": {},
            "id": 137168,
            "title": {
                "english": "Behaviour",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "cooperative-ability": {
            "components": {},
            "id": 137170,
            "title": {
                "english": "Cooperative Ability",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "education": {
            "components": {},
            "id": 137165,
            "title": {
                "english": "Education",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "attitude": {
            "components": {},
            "id": 137167,
            "title": {
                "english": "Attitude",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "social-skills": {
            "components": {},
            "id": 137169,
            "title": {
                "english": "Social Skills",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        }
    },
    "authors": [
        {
            "name": "Eva Norrby"
        },
        {
            "name": "Iréne Linddahl"
        }
    ],
    "reference_title": "Dialogue about work ability (DWA)"
}