
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
    "reference_year": "2003",
    "id": 139798,
    "title": {
        "english": "Engle and Romano’s (1977) Continuum of Natural Systems",
        "svenska": "Engle and Romano’s (1977) Continuum of Natural Systems"
    },
    "components": {
        "organelles": {
            "components": {},
            "id": 139812,
            "title": {
                "english": "Organelles",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "culture-subculture": {
            "components": {},
            "id": 139801,
            "title": {
                "english": "Culture-Subculture",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "biosphere": {
            "components": {},
            "id": 139799,
            "title": {
                "english": "Biosphere",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "molecules": {
            "components": {},
            "id": 139813,
            "title": {
                "english": "Molecules",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "tissues": {
            "components": {},
            "id": 139810,
            "title": {
                "english": "Tissues",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "nervous-system": {
            "components": {},
            "id": 139808,
            "title": {
                "english": "Nervous System",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "family": {
            "components": {},
            "id": 139803,
            "title": {
                "english": "Family",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "cells": {
            "components": {},
            "id": 139811,
            "title": {
                "english": "Cells",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "dyad": {
            "components": {},
            "id": 139804,
            "title": {
                "english": "Dyad",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "organ-organ-system": {
            "components": {},
            "id": 139809,
            "title": {
                "english": "Organ/Organ System",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "person": {
            "components": {
                "experience": {
                    "components": {},
                    "id": 139806,
                    "title": {
                        "english": "Experience",
                        "svenska": ""
                    },
                    "level": 0,
                    "description": {
                        "english": "",
                        "svenska": ""
                    }
                },
                "behavior": {
                    "components": {},
                    "id": 139807,
                    "title": {
                        "english": "Behavior",
                        "svenska": ""
                    },
                    "level": 0,
                    "description": {
                        "english": "",
                        "svenska": ""
                    }
                }
            },
            "id": 139805,
            "title": {
                "english": "Person",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "atoms": {
            "components": {},
            "id": 139814,
            "title": {
                "english": "Atoms",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "community": {
            "components": {},
            "id": 139802,
            "title": {
                "english": "Community",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "society-nation": {
            "components": {},
            "id": 139800,
            "title": {
                "english": "Society-Nation",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "subatomic-particles": {
            "components": {},
            "id": 139815,
            "title": {
                "english": "Subatomic Particles",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        }
    },
    "authors": [
        {
            "name": "Richard M. Frankel"
        },
        {
            "name": "Susan H. McDaniel"
        },
        {
            "name": "Timothy E. Quill"
        }
    ],
    "reference_title": "The Biopsychosocial Approach: Past. Present. Future"
}