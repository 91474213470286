
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
	"id": 140924,
	"title": {
		"english": "Blumberg and Pringle’s (1982) Model of Effective Job Performance",
		"svenska": "Blumberg and Pringle’s (1982) Model of Effective Job Performance"
	},
	"authors": [
		{
			"name": "Nico W. Van Yperen"
		},
		{
			"name": "Andrew J. Elliot"
		},
		{
			"name": "Carol S. Dweck"
		},
		{
			"name": "David S. Yeager"
		}
	],
	"reference_title": "Handbook of Competence and Motivation, Second Edition",
	"reference_year": "2017",
	"components": {
		"competence": {
			"id": 140925,
			"title": {
				"english": "Competence",
				"svenska": ""
			},
			"description": {
				"english": "The capabilities that enable workers to perform their tasks effectively.",
				"svenska": ""
			},
			"level": 0,
			"parents": [
				140924
			],
			"components": {
				"physical-capabilities": {
					"id": 140926,
					"title": {
						"english": "Physical Capabilities",
						"svenska": ""
					},
					"description": {
						"english": "",
						"svenska": ""
					},
					"level": 0,
					"parents": [
						140925
					],
					"components": {
						"physical-abilities": {
							"id": 140930,
							"title": {
								"english": "Physical Abilities",
								"svenska": ""
							},
							"description": {
								"english": "",
								"svenska": ""
							},
							"level": 0,
							"parents": [
								140926
							],
							"components": {}
						},
						"physical-skills": {
							"id": 140930,
							"title": {
								"english": "Physical Skills",
								"svenska": ""
							},
							"description": {
								"english": "",
								"svenska": ""
							},
							"level": 0,
							"parents": [
								140926
							],
							"components": {}
						}
					}
				},
				"cognitive-capabilities": {
					"id": 140927,
					"title": {
						"english": "Cognitive Capabilities",
						"svenska": ""
					},
					"description": {
						"english": "",
						"svenska": ""
					},
					"level": 0,
					"parents": [
						140925
					],
					"components": {
						"knowledge": {
							"id": 140928,
							"title": {
								"english": "Knowledge",
								"svenska": ""
							},
							"description": {
								"english": "",
								"svenska": ""
							},
							"level": 0,
							"parents": [
								140927
							],
							"components": {}
						},
						"cog-skills": {
							"id": 140929,
							"title": {
								"english": "Cognitive Skills",
								"svenska": ""
							},
							"description": {
								"english": "",
								"svenska": ""
							},
							"level": 0,
							"parents": [
								140927
							],
							"components": {}
						},
						"cog-abilities": {
							"id": 140929,
							"title": {
								"english": "Cognitive Abilities",
								"svenska": ""
							},
							"description": {
								"english": "",
								"svenska": ""
							},
							"level": 0,
							"parents": [
								140927
							],
							"components": {}
						}
					}
				}
			}
		},
		"opportunity": {
			"id": 140931,
			"title": {
				"english": "Opportunity",
				"svenska": ""
			},
			"description": {
				"english": "Opportunity to perform refers to the help or hindrance of uncontrollable events and actors in one’s environment",
				"svenska": ""
			},
			"level": 0,
			"parents": [
				140924
			],
			"components": {
				"working-conditions": {
					"id": 140932,
					"title": {
						"english": "Working Conditions",
						"svenska": ""
					},
					"description": {
						"english": "",
						"svenska": ""
					},
					"level": 0,
					"parents": [
						140931
					],
					"components": {}
				},
				"equipment": {
					"id": 140933,
					"title": {
						"english": "Equipment",
						"svenska": ""
					},
					"description": {
						"english": "",
						"svenska": ""
					},
					"level": 0,
					"parents": [
						140931
					],
					"components": {}
				},
				"social-support": {
					"id": 140934,
					"title": {
						"english": "Social Support",
						"svenska": ""
					},
					"description": {
						"english": "",
						"svenska": ""
					},
					"level": 0,
					"parents": [
						140931
					],
					"components": {}
				},
				"organizational-policies": {
					"id": 140935,
					"title": {
						"english": "Organizational Policies",
						"svenska": ""
					},
					"description": {
						"english": "",
						"svenska": ""
					},
					"level": 0,
					"parents": [
						140931
					],
					"components": {}
				}
			}
		},
		"willingness": {
			"id": 140936,
			"title": {
				"english": "Willingness",
				"svenska": ""
			},
			"description": {
				"english": "Individuals’ psychological characteristics that affect the degree to which they are inclined to perform.",
				"svenska": ""
			},
			"level": 0,
			"parents": [
				140924
			],
			"components": {
				"appetitive-possibility": {
					"id": 140937,
					"title": {
						"english": "Appetitive Possibility",
						"svenska": ""
					},
					"description": {
						"english": "",
						"svenska": ""
					},
					"level": 0,
					"parents": [
						140936
					],
					"components": {}
				},
				"adversive-possibility": {
					"id": 140938,
					"title": {
						"english": "Adversive Possibility",
						"svenska": ""
					},
					"description": {
						"english": "",
						"svenska": ""
					},
					"level": 0,
					"parents": [
						140936
					],
					"components": {}
				}
			}
		}
	},
	"level": 0
}