
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
"reference_year" : "2020",
"id" : 139628,
"title" : {
	"english" : "Ludvigsson et al’s (2006) Taxonomy of Work Ability",
	"svenska" : "Ludvigsson et al’s (2006) Taxonomy of Work Ability"
},
"components" : {
	"physical-ability" : {
	"components" : {

	},
	"id" : 139629,
	"title" : {
		"english" : "Physical Ability",
		"svenska" : ""
	},
	"level" : 0,
	"description" : {
		"english" : "Physical ability is the one most often associated with ability to work",
		"svenska" : ""
	}
	},
	"social-ability" : {
	"components" : {

	},
	"id" : 139631,
	"title" : {
		"english" : "Social Ability",
		"svenska" : ""
	},
	"level" : 0,
	"description" : {
		"english" : "The social dimension is based on social behavior in work situations.",
		"svenska" : ""
	}
	},
	"mental-ability" : {
	"components" : {

	},
	"id" : 139630,
	"title" : {
		"english" : "Mental Ability",
		"svenska" : ""
	},
	"level" : 0,
	"description" : {
		"english" : "The mental dimension involves impairments in the form of anxiety and\/or fatigue related depression i.e. mental capacity.",
		"svenska" : ""
	}
	}
},
"authors" : [
	{
	"name" : "Eva Norrby"
	},
	{
	"name" : "Iréne Linddahl"
	}
],
"reference_title" : "Dialogue about work ability (DWA)"
}