
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
    "reference_year": "1997",
    "id": 134291,
    "title": {
        "english": "Krippendorff’s (1997) Trajectory of Artificiality",
        "svenska": "Krippendorff’s (1997) Trajectory of Artificiality"
    },
    "components": {
        "products": {
            "components": {
                "utility": {
                    "components": {},
                    "id": 134338,
                    "title": {
                        "english": "Utility",
                        "svenska": ""
                    },
                    "level": 0,
                    "description": {
                        "english": "",
                        "svenska": ""
                    }
                },
                "functionality": {
                    "components": {},
                    "id": 134339,
                    "title": {
                        "english": "Functionality",
                        "svenska": ""
                    },
                    "level": 0,
                    "description": {
                        "english": "",
                        "svenska": ""
                    }
                },
                "universal-aesthetics": {
                    "components": {},
                    "id": 134340,
                    "title": {
                        "english": "Universal Aesthetics",
                        "svenska": ""
                    },
                    "level": 0,
                    "description": {
                        "english": "",
                        "svenska": ""
                    }
                }
            },
            "id": 134317,
            "title": {
                "english": "Products",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "interfaces": {
            "components": {
                "interactivity": {
                    "components": {},
                    "id": 134309,
                    "title": {
                        "english": "Interactivity",
                        "svenska": ""
                    },
                    "level": 0,
                    "description": {
                        "english": "",
                        "svenska": ""
                    }
                },
                "configurability": {
                    "components": {},
                    "id": 134306,
                    "title": {
                        "english": "Configurability",
                        "svenska": ""
                    },
                    "level": 0,
                    "description": {
                        "english": "",
                        "svenska": ""
                    }
                },
                "adaptability": {
                    "components": {},
                    "id": 134307,
                    "title": {
                        "english": "Adaptability",
                        "svenska": ""
                    },
                    "level": 0,
                    "description": {
                        "english": "",
                        "svenska": ""
                    }
                },
                "understandability": {
                    "components": {},
                    "id": 134310,
                    "title": {
                        "english": "Understandability",
                        "svenska": ""
                    },
                    "level": 0,
                    "description": {
                        "english": "",
                        "svenska": ""
                    }
                }
            },
            "id": 134308,
            "title": {
                "english": "Interfaces",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "goods--services-and-identities": {
            "components": {
                "marketability": {
                    "components": {},
                    "id": 134335,
                    "title": {
                        "english": "Marketability",
                        "svenska": ""
                    },
                    "level": 0,
                    "description": {
                        "english": "",
                        "svenska": ""
                    }
                },
                "provincial-aesthetics": {
                    "components": {},
                    "id": 134337,
                    "title": {
                        "english": "Provincial Aesthetics",
                        "svenska": ""
                    },
                    "level": 0,
                    "description": {
                        "english": "",
                        "svenska": ""
                    }
                },
                "symbolic-qualities": {
                    "components": {},
                    "id": 134336,
                    "title": {
                        "english": "Symbolic Qualities",
                        "svenska": ""
                    },
                    "level": 0,
                    "description": {
                        "english": "",
                        "svenska": ""
                    }
                }
            },
            "id": 134313,
            "title": {
                "english": "Goods, Services and Identities",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "multi-user-systems": {
            "components": {
                "connectivity": {
                    "components": {},
                    "id": 134312,
                    "title": {
                        "english": "Connectivity",
                        "svenska": ""
                    },
                    "level": 0,
                    "description": {
                        "english": "",
                        "svenska": ""
                    }
                },
                "accessibility": {
                    "components": {},
                    "id": 134311,
                    "title": {
                        "english": "Accessibility",
                        "svenska": ""
                    },
                    "level": 0,
                    "description": {
                        "english": "",
                        "svenska": ""
                    }
                },
                "informaticity": {
                    "components": {},
                    "id": 134304,
                    "title": {
                        "english": "Informaticity",
                        "svenska": ""
                    },
                    "level": 0,
                    "description": {
                        "english": "",
                        "svenska": ""
                    }
                }
            },
            "id": 134303,
            "title": {
                "english": "Multi-user Systems",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "projects": {
            "components": {
                "social": {
                    "components": {},
                    "id": 134297,
                    "title": {
                        "english": "Social",
                        "svenska": ""
                    },
                    "level": 0,
                    "description": {
                        "english": "",
                        "svenska": ""
                    }
                },
                "commitment": {
                    "components": {},
                    "id": 134300,
                    "title": {
                        "english": "Commitment",
                        "svenska": ""
                    },
                    "level": 0,
                    "description": {
                        "english": "",
                        "svenska": ""
                    }
                },
                "directionality": {
                    "components": {},
                    "id": 134299,
                    "title": {
                        "english": "Directionality",
                        "svenska": ""
                    },
                    "level": 0,
                    "description": {
                        "english": "",
                        "svenska": ""
                    }
                },
                "viability": {
                    "components": {},
                    "id": 134298,
                    "title": {
                        "english": "Viability",
                        "svenska": ""
                    },
                    "level": 0,
                    "description": {
                        "english": "",
                        "svenska": ""
                    }
                }
            },
            "id": 134296,
            "title": {
                "english": "Projects",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "discourses": {
            "components": {
                "rearticulability": {
                    "components": {},
                    "id": 134294,
                    "title": {
                        "english": "Rearticulability",
                        "svenska": ""
                    },
                    "level": 0,
                    "description": {
                        "english": "",
                        "svenska": ""
                    }
                },
                "generativity": {
                    "components": {},
                    "id": 134293,
                    "title": {
                        "english": "Generativity",
                        "svenska": ""
                    },
                    "level": 0,
                    "description": {
                        "english": "",
                        "svenska": ""
                    }
                },
                "solidarity": {
                    "components": {},
                    "id": 134295,
                    "title": {
                        "english": "Solidarity",
                        "svenska": ""
                    },
                    "level": 0,
                    "description": {
                        "english": "",
                        "svenska": ""
                    }
                }
            },
            "id": 134292,
            "title": {
                "english": "Discourses",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        }
    },
    "authors": [
        {
            "name": "Daniel Boyarski"
        },
        {
            "name": "Reinhart Butter"
        },
        {
            "name": "Klaus Krippendorff"
        },
        {
            "name": "Richard Solomon"
        },
        {
            "name": "James Tomlinson"
        },
        {
            "name": "Walter Wiebe"
        }
    ],
    "reference_title": "Design in the Age of Information: A Report to the National Science Foundation (NSF)"
}