import janus_json from "../../../../data-models/tpg-models/tpg_patient.jsonc";
import deduped_janus from "../../../../data-models/tpg-models/tpg_patient_deduped.jsonc";
import janus_overview from "../../../../svg-data/Janus_Overview.json";
import janus_overview_data from "../../../../data-models/work-capacity-models/janus-overview.jsonc";
import janus_graph from "../../../../svg-data/Janus_Graph.json";
import simplified_janus_graph from "../../../../svg-data/Simplified_Janus_Overview.json";
import simplified_tpg from "../../../../data-models/tpg-models/simplified_tpg.jsonc";
import bip_overview from "../../../../svg-data/BIP_Overview.json";
import simplified_bip_overview from "../../../../svg-data/Simplified_BIP_Overview.json";
import patient_vector from "../../../../svg-data/tpg_patient_vector.json";
import * as React from 'react';
export default {
  janus_json,
  deduped_janus,
  janus_overview,
  janus_overview_data,
  janus_graph,
  simplified_janus_graph,
  simplified_tpg,
  bip_overview,
  simplified_bip_overview,
  patient_vector,
  React
};