
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
    "reference_year": "2012",
    "id": 139604,
    "title": {
        "english": "Væksthusets’ (2012) Conceptual Model of Factors Affecting Job-Readiness",
        "svenska": "Væksthusets’ (2012) Conceptual Model of Factors Affecting Job-Readiness"
    },
    "components": {
        "personal-skills": {
            "components": {},
            "id": 139610,
            "title": {
                "english": "Personal Skills",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "Personlige kompetencer",
                "svenska": ""
            }
        },
        "identity-and-mastery": {
            "components": {},
            "id": 139607,
            "title": {
                "english": "Identity And Mastery",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "Identitet og mestring",
                "svenska": ""
            }
        },
        "mental-health": {
            "components": {},
            "id": 139612,
            "title": {
                "english": "Mental Health",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "psykisk helbred",
                "svenska": ""
            }
        },
        "job-search": {
            "components": {},
            "id": 139606,
            "title": {
                "english": "Job Search",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "Jobsøgning",
                "svenska": ""
            }
        },
        "physical-health": {
            "components": {},
            "id": 139611,
            "title": {
                "english": "Physical Health",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "Fysisk helbred",
                "svenska": ""
            }
        },
        "professional-skills": {
            "components": {},
            "id": 139605,
            "title": {
                "english": "Professional Skills",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "Faglige kompetencer",
                "svenska": ""
            }
        },
        "labor-market-readiness": {
            "components": {},
            "id": 139608,
            "title": {
                "english": "Labor Market Readiness",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "Arbejdsmarkedsparathed",
                "svenska": ""
            }
        },
        "social-skills": {
            "components": {},
            "id": 139609,
            "title": {
                "english": "Social Skills",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "Sociale kompetencer",
                "svenska": ""
            }
        }
    },
    "authors": [
        {
            "name": "Væksthusets Forskningscenter"
        }
    ],
    "reference_title": "Litteraturreview ifm. Beskæftigelses Indikator Projektet (BIP)"
}