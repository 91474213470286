
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
    "reference_year": "2003",
    "id": 136401,
    "title": {
        "english": "LiSat-11 Life Satisfaction Model",
        "svenska": "LiSat-11 Life Satisfaction Model"
    },
    "components": {
        "my-sexual-life": {
            "components": {},
            "id": 136403,
            "title": {
                "english": "My Sexual Life",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "my-financial-situation": {
            "components": {},
            "id": 136410,
            "title": {
                "english": "My Financial Situation",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "my-family-life": {
            "components": {},
            "id": 136405,
            "title": {
                "english": "My Family Life",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "my-contact-with-friends-and-acquaintances": {
            "components": {},
            "id": 136402,
            "title": {
                "english": "My Contact With Friends And Acquaintances",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "my-life-as-a-whole": {
            "components": {},
            "id": 136412,
            "title": {
                "english": "My Life As A Whole",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "my-psychological-health": {
            "components": {},
            "id": 136408,
            "title": {
                "english": "My Psychological Health",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "my-partner-relationship": {
            "components": {},
            "id": 136406,
            "title": {
                "english": "My Partner Relationship",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "my-physical-health": {
            "components": {},
            "id": 136407,
            "title": {
                "english": "My Physical Health",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "my-leisure-situation": {
            "components": {},
            "id": 136411,
            "title": {
                "english": "My Leisure Situation",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "my-vocational-situation": {
            "components": {},
            "id": 136409,
            "title": {
                "english": "My Vocational Situation",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "my-ability-to-manage-my-self--care--dressing--hygiene-etc--": {
            "components": {},
            "id": 136404,
            "title": {
                "english": "My Ability To Manage My Self- Care (Dressing, Hygiene etc.)",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        }
    },
    "authors": [
        {
            "name": "Roland Melin"
        }
    ],
    "reference_title": "On Life Satisfaction and Vocational Rehabilitation"
}