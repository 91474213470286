
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
    "reference_year": "2017",
    "id": 135331,
    "title": {
        "english": "Fidler & Fidler’s Model of Human Occupation",
        "svenska": "Fidler & Fidler’s Model of Human Occupation"
    },
    "components": {
        "physical-context": {
            "components": {},
            "id": 135337,
            "title": {
                "english": "Physical Context",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "sociocultural-context": {
            "components": {},
            "id": 135336,
            "title": {
                "english": "Sociocultural Context",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "temporal-context": {
            "components": {
				"activities-of-daily-living": {
					"components": {
						"productivity": {
							"components": {},
							"id": 135334,
							"title": {
								"english": "Productivity",
								"svenska": ""
							},
							"level": 0,
							"description": {
								"english": "",
								"svenska": ""
							}
						},
						"play": {
							"components": {},
							"id": 135333,
							"title": {
								"english": "Play",
								"svenska": ""
							},
							"level": 0,
							"description": {
								"english": "",
								"svenska": ""
							}
						}
					},
					"id": 135332,
					"title": {
						"english": "Activities Of Daily Living",
						"svenska": ""
					},
					"level": 0,
					"description": {
						"english": "",
						"svenska": ""
					}
				}
			},
            "id": 135335,
            "title": {
                "english": "Temporal Context",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        }
    },
    "authors": [
        {
            "name": "Renee Taylor"
        }
    ],
    "reference_title": "Kielhofner’s Model of Human Occupation"
}