
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
    "reference_year": "2019",
    "id": 135464,
    "title": {
        "english": "The 11 BIP Indicators for Employment",
        "svenska": "The 11 BIP Indicators for Employment"
    },
    "components": {
        "social-support": {
            "components": {},
            "id": 135474,
            "title": {
                "english": "Social Support",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "client-s-confidence-in-own-job-prospects": {
            "components": {},
            "id": 135470,
            "title": {
                "english": "Client’s Confidence In Own Job Prospects",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "determination": {
            "components": {},
            "id": 135475,
            "title": {
                "english": "Determination",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "health-and-health-coping-skills": {
            "components": {},
            "id": 135466,
            "title": {
                "english": "Health And Health Coping Skills",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "job-readiness": {
            "components": {},
            "id": 135472,
            "title": {
                "english": "Job Readiness",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "daily-coping-skills": {
            "components": {},
            "id": 135471,
            "title": {
                "english": "Daily Coping Skills",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "instructional-understanding": {
            "components": {},
            "id": 135469,
            "title": {
                "english": "Instructional Understanding",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "knowledge-of-the-labour-market": {
            "components": {},
            "id": 135467,
            "title": {
                "english": "Knowledge Of The Labour Market",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "caseworker-s-confidence-in-the-client-s-job-prospects": {
            "components": {},
            "id": 135473,
            "title": {
                "english": "Caseworker’s Confidence In The Client’s Job Prospects",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "cooperation-skills": {
            "components": {},
            "id": 135476,
            "title": {
                "english": "Cooperation Skills",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "job-search-behaviour": {
            "components": {},
            "id": 135465,
            "title": {
                "english": "Job Search Behaviour",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "ability-to-initiate-contact": {
            "components": {},
            "id": 135477,
            "title": {
                "english": "Ability To Initiate Contact",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "concentration-ability": {
            "components": {},
            "id": 135468,
            "title": {
                "english": "Concentration Ability",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        }
    },
    "authors": [
        {
            "name": "Væksthusets Forskningscenter"
        }
    ],
    "reference_title": "What Affects Job Prospects? (BIP)"
}