
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
    "reference_year": "2015",
    "id": 134941,
    "title": {
        "english": "International Classification of Functioning, Disability and Health",
        "svenska": "International Classification of Functioning, Disability and Health"
    },
    "components": {
        "activity": {
            "components": {
                "moving": {
                    "components": {},
                    "id": 134958,
                    "title": {
                        "english": "Moving",
                        "svenska": ""
                    },
                    "level": 0,
                    "description": {
                        "english": "",
                        "svenska": ""
                    }
                },
                "housework": {
                    "components": {},
                    "id": 134960,
                    "title": {
                        "english": "Housework",
                        "svenska": ""
                    },
                    "level": 0,
                    "description": {
                        "english": "",
                        "svenska": ""
                    }
                },
                "eating": {
                    "components": {},
                    "id": 134959,
                    "title": {
                        "english": "Eating",
                        "svenska": ""
                    },
                    "level": 0,
                    "description": {
                        "english": "",
                        "svenska": ""
                    }
                }
            },
            "id": 134954,
            "title": {
                "english": "Activity",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "participation": {
            "components": {
                "community-engagement": {
                    "components": {},
                    "id": 134962,
                    "title": {
                        "english": "Community Engagement",
                        "svenska": ""
                    },
                    "level": 0,
                    "description": {
                        "english": "",
                        "svenska": ""
                    }
                },
                "employment": {
                    "components": {},
                    "id": 134961,
                    "title": {
                        "english": "Employment",
                        "svenska": ""
                    },
                    "level": 0,
                    "description": {
                        "english": "",
                        "svenska": ""
                    }
                }
            },
            "id": 134955,
            "title": {
                "english": "Participation",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "body": {
            "components": {
                "body-functions": {
                    "components": {
                        "sensory": {
                            "components": {
                                "hearing": {
                                    "components": {},
                                    "id": 134957,
                                    "title": {
                                        "english": "Hearing",
                                        "svenska": ""
                                    },
                                    "level": 0,
                                    "description": {
                                        "english": "",
                                        "svenska": ""
                                    }
                                },
                                "vision": {
                                    "components": {},
                                    "id": 134956,
                                    "title": {
                                        "english": "Vision",
                                        "svenska": ""
                                    },
                                    "level": 0,
                                    "description": {
                                        "english": "",
                                        "svenska": ""
                                    }
                                }
                            },
                            "id": 134945,
                            "title": {
                                "english": "Sensory",
                                "svenska": ""
                            },
                            "level": 0,
                            "description": {
                                "english": "",
                                "svenska": ""
                            }
                        },
                        "cardiovascular": {
                            "components": {},
                            "id": 134944,
                            "title": {
                                "english": "Cardiovascular",
                                "svenska": ""
                            },
                            "level": 0,
                            "description": {
                                "english": "",
                                "svenska": ""
                            }
                        }
                    },
                    "id": 134942,
                    "title": {
                        "english": "Body Functions",
                        "svenska": ""
                    },
                    "level": 0,
                    "description": {
                        "english": "",
                        "svenska": ""
                    }
                },
                "psychic-functions": {
                    "components": {
                        "temperament---personality": {
                            "components": {},
                            "id": 134951,
                            "title": {
                                "english": "Temperament / Personality",
                                "svenska": ""
                            },
                            "level": 0,
                            "description": {
                                "english": "",
                                "svenska": ""
                            }
                        },
                        "memory": {
                            "components": {},
                            "id": 134950,
                            "title": {
                                "english": "Memory",
                                "svenska": ""
                            },
                            "level": 0,
                            "description": {
                                "english": "",
                                "svenska": ""
                            }
                        },
                        "emotions": {
                            "components": {},
                            "id": 134952,
                            "title": {
                                "english": "Emotions",
                                "svenska": ""
                            },
                            "level": 0,
                            "description": {
                                "english": "",
                                "svenska": ""
                            }
                        }
                    },
                    "id": 134949,
                    "title": {
                        "english": "Psychic Functions",
                        "svenska": ""
                    },
                    "level": 0,
                    "description": {
                        "english": "",
                        "svenska": ""
                    }
                },
                "body-structure": {
                    "components": {
                        "nerves": {
                            "components": {},
                            "id": 134947,
                            "title": {
                                "english": "Nerves",
                                "svenska": ""
                            },
                            "level": 0,
                            "description": {
                                "english": "",
                                "svenska": ""
                            }
                        },
                        "limbs": {
                            "components": {},
                            "id": 134948,
                            "title": {
                                "english": "Limbs",
                                "svenska": ""
                            },
                            "level": 0,
                            "description": {
                                "english": "",
                                "svenska": ""
                            }
                        },
                        "skeleton": {
                            "components": {},
                            "id": 134946,
                            "title": {
                                "english": "Skeleton",
                                "svenska": ""
                            },
                            "level": 0,
                            "description": {
                                "english": "",
                                "svenska": ""
                            }
                        }
                    },
                    "id": 134943,
                    "title": {
                        "english": "Body Structure",
                        "svenska": ""
                    },
                    "level": 0,
                    "description": {
                        "english": "",
                        "svenska": ""
                    }
                }
            },
            "id": 134953,
            "title": {
                "english": "Body",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        }
    },
    "authors": [
        {
            "name": "Kerstin Ekberg"
        },
        {
            "name": "Mona Eklund"
        },
        {
            "name": "Gunnel Hensing"
        }
    ],
    "reference_title": "Återgång i arbete"
}