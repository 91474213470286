
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
    "reference_year": "2021",
    "id": 134322,
    "title": {
        "english": "Updated Janus Model",
        "svenska": "Updated Janus Model"
    },
    "components": {
        "technical": {
            "components": {},
            "id": 134330,
            "title": {
                "english": "Technical",
                "svenska": "Teknisk"
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "resourcefulness": {
            "components": {},
            "id": 134334,
            "title": {
                "english": "Resourcefulness",
                "svenska": "Rådighet"
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "social-activity": {
            "components": {},
            "id": 134324,
            "title": {
                "english": "Social Activity",
                "svenska": "Social aktivitet"
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "financial": {
            "components": {},
            "id": 134325,
            "title": {
                "english": "Financial",
                "svenska": "Finansiell"
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "wellbeing": {
            "components": {},
            "id": 134333,
            "title": {
                "english": "Wellbeing",
                "svenska": "Välbefinnande"
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "creativity": {
            "components": {},
            "id": 134329,
            "title": {
                "english": "Creativity",
                "svenska": "Kreativitet"
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "physical": {
            "components": {},
            "id": 134326,
            "title": {
                "english": "Physical",
                "svenska": "Fysisk"
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "self-discipline": {
            "components": {},
            "id": 134327,
            "title": {
                "english": "Self-Discipline",
                "svenska": "Självdisciplin"
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "self-reflection": {
            "components": {},
            "id": 134328,
            "title": {
                "english": "Self-Reflection",
                "svenska": "Självreflektion"
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "empowerment": {
            "components": {},
            "id": 134332,
            "title": {
                "english": "Empowerment",
                "svenska": "Bemyndiga"
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "timekeeping": {
            "components": {},
            "id": 134331,
            "title": {
                "english": "Timekeeping",
                "svenska": "Tidtagning"
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        }
    },
    "authors": [
        {
            "name": "Thomson et al"
        }
    ],	
    "reference_title": "The Use of Logistic Regression in Vocational Rehabilitation Factor Analysis"
}