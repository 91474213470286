
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
    "id": 140851,
    "title": {
		"english" : "Sandqvist’s (2006) Framework for Assessment of Work Performance",
		"svenska" : "Sandqvist’s (2006) Framework for Assessment of Work Performance"
	},
    "authors": [
        {
            "name": "Jan L. Sandqvist"
        },
        {
            "name": "Mathilda A. Björk"
        },
        {
            "name": "Mats T. Gullberg"
        },
        {
            "name": "Chris M. Henriksson"
        },
        {
            "name": "Björn U.c. Gerdle"
        }
    ],
    "reference_title": "Construct validity of the Assessment of Work Performance (AWP)",
    "reference_year": "2019",
    "components": {
        "motor-skills": {
            "id": 140852,
            "title": {
                "english": "Motor Skills",
                "svenska": ""
            },
            "description": {
                "english": "",
                "svenska": ""
            },
            "level": 0,
            "parents": [
                140851
            ],
            "components": {
                "posture": {
                    "id": 140853,
                    "title": {
                        "english": "Posture",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 0,
                    "parents": [
                        140852
                    ],
                    "components": {}
                },
                "mobility": {
                    "id": 140854,
                    "title": {
                        "english": "Mobility",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 0,
                    "parents": [
                        140852
                    ],
                    "components": {}
                },
                "coordination": {
                    "id": 140855,
                    "title": {
                        "english": "Coordination",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 0,
                    "parents": [
                        140852
                    ],
                    "components": {}
                },
                "strength-and-handling-of-objects": {
                    "id": 140856,
                    "title": {
                        "english": "Strength And Handling Of Objects",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 0,
                    "parents": [
                        140852
                    ],
                    "components": {}
                },
                "physical-energy": {
                    "id": 140857,
                    "title": {
                        "english": "Physical Energy",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 0,
                    "parents": [
                        140852
                    ],
                    "components": {}
                }
            }
        },
        "process-skills": {
            "id": 140858,
            "title": {
                "english": "Process Skills",
                "svenska": ""
            },
            "description": {
                "english": "",
                "svenska": ""
            },
            "level": 0,
            "parents": [
                140851
            ],
            "components": {
                "mental-energy": {
                    "id": 140859,
                    "title": {
                        "english": "Mental Energy",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 0,
                    "parents": [
                        140858
                    ],
                    "components": {}
                },
                "knowledge": {
                    "id": 140860,
                    "title": {
                        "english": "Knowledge",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 0,
                    "parents": [
                        140858
                    ],
                    "components": {}
                },
                "temporal-organization": {
                    "id": 140861,
                    "title": {
                        "english": "Temporal Organization",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 0,
                    "parents": [
                        140858
                    ],
                    "components": {}
                },
                "organization-of-workplace": {
                    "id": 140862,
                    "title": {
                        "english": "Organization Of Workplace",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 0,
                    "parents": [
                        140858
                    ],
                    "components": {}
                },
                "adaptation": {
                    "id": 140863,
                    "title": {
                        "english": "Adaptation",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 0,
                    "parents": [
                        140858
                    ],
                    "components": {}
                }
            }
        },
        "communication-and-interaction-skills": {
            "id": 140864,
            "title": {
                "english": "Communication And Interaction Skills",
                "svenska": ""
            },
            "description": {
                "english": "",
                "svenska": ""
            },
            "level": 0,
            "parents": [
                140851
            ],
            "components": {
                "information-exchange": {
                    "id": 140868,
                    "title": {
                        "english": "Information Exchange",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 0,
                    "parents": [
                        140864
                    ],
                    "components": {}
                },
                "physical-communication-and-interaction": {
                    "id": 140865,
                    "title": {
                        "english": "Physical Communication And Interaction",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 0,
                    "parents": [
                        140864
                    ],
                    "components": {}
                },
                "language": {
                    "id": 140866,
                    "title": {
                        "english": "Language",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 0,
                    "parents": [
                        140864
                    ],
                    "components": {}
                },
                "social-contacts": {
                    "id": 140867,
                    "title": {
                        "english": "Social Contacts",
                        "svenska": ""
                    },
                    "description": {
                        "english": "",
                        "svenska": ""
                    },
                    "level": 0,
                    "parents": [
                        140864
                    ],
                    "components": {}
                }
            }
        }
    },
    "level": 0
}