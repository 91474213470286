
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
    "reference_year": "2016",
    "id": 133842,
    "title": {
        "english": "Knauf and Schultz’s (2021) Model of the Paradigms of Return to Work",
        "svenska": "Knauf and Schultz’s (2021) Model of the Paradigms of Return to Work"
    },
    "components": {
        "biomedical": {
            "components": {},
            "id": 133843,
            "title": {
                "english": "Biomedical",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "Within the biomedical approach, disability is produced by a medical condition that is an identified, observable deviation from biomedical norms of function or structure. Disability is viewed as a personal problem that requires medical treatment. Factors such as context and environment are not considered (Bickenbach et al. 1999; Boorse 1975, 1977; Reed et al. 2008; Schultz et al. 2000, 2007; Smart 2001; WHO 2001)",
                "svenska": ""
            }
        },
        "social-construction": {
            "components": {},
            "id": 133844,
            "title": {
                "english": "Social Construction",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "Within the social construction paradigm, disability is viewed as complex combination of activities, relationships, individual attributes, and conditions arising mainly from the social environment of the individual (Bickenbach et al. 1999; DePoy and Gilson 2004; Tate and Pledger 2003; Olkin and Pledger 2003). Disability depends on a societal response in a given context; thus, with an appropriate response, disability would not exist (Smart 2001; Smart and Smart 2007)",
                "svenska": ""
            }
        },
        "biopsychosocial": {
            "components": {
                "micro-interactional": {
                    "components": {},
                    "id": 133846,
                    "title": {
                        "english": "Micro-Interactional",
                        "svenska": ""
                    },
                    "level": 1,
                    "description": {
                        "english": "Individually oriented",
                        "svenska": ""
                    }
                },
				"meso-interactional": {
					"components": {},
					"id": 133847,
					"title": {
						"english": "Meso-Interactional",
						"svenska": ""
					},
					"level": 1,
					"description": {
						"english": "Organisational or community-oriented",
						"svenska": ""
					}
				},
                "macro-interactional": {
                    "components": {},
                    "id": 133848,
                    "title": {
                        "english": "Macro-Interactional",
                        "svenska": ""
                    },
                    "level": 1,
                    "description": {
                        "english": "Structurally-oriented",
                        "svenska": ""
                    }
                }
            },
            "id": 133845,
            "title": {
                "english": "Biopsychosocial",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "The biopsychosocial approach is informed by both social and the biomedical paradigms.",
                "svenska": ""
            }
        }
    },
    "authors": [
        {
            "name": "Isabel Z. Schultz"
        },
        {
            "name": "Robert J. Gatchel"
        }
    ],
    "reference_title": "Handbook of Return to Work"
}