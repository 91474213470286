
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
    "reference_year": "2012",
    "id": 139593,
    "title": {
        "english": "Vaeksthuset’s (2012) Model of the Characteristics of ‘Non-Labour Market Ready Individuals’",
        "svenska": "Vaeksthuset’s (2012) Model of the Characteristics of ‘Non-Labour Market Ready Individuals’"
    },
    "components": {
        "addiction-problems": {
            "components": {},
            "id": 139596,
            "title": {
                "english": "Addiction Problems",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "housing-problems": {
            "components": {},
            "id": 139602,
            "title": {
                "english": "Housing Problems",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "language-problems": {
            "components": {},
            "id": 139601,
            "title": {
                "english": "Language Problems",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "health-problems": {
            "components": {},
            "id": 139599,
            "title": {
                "english": "Health Problems",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "lack-of-educational-background": {
            "components": {},
            "id": 139594,
            "title": {
                "english": "Lack Of Educational Background",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "socially-maladapted": {
            "components": {},
            "id": 139595,
            "title": {
                "english": "Socially Maladapted",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "debt-problems": {
            "components": {},
            "id": 139598,
            "title": {
                "english": "Debt Problems",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "psychological-problems": {
            "components": {},
            "id": 139600,
            "title": {
                "english": "Psychological Problems",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "family-problems": {
            "components": {},
            "id": 139597,
            "title": {
                "english": "Family Problems",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        }
    },
    "authors": [
        {
            "name": "Væksthusets Forskningscenter"
        }
    ],
    "reference_title": "Litteraturreview ifm. Beskæftigelses Indikator Projektet (BIP)"
}