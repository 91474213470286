
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
    "reference_year": "2020",
    "id": 137188,
    "title": {
        "english": "Simplified BIP (Spider) Model",
        "svenska": "Simplified BIP (Spider) Model"
    },
    "components": {
        "everyday": {
            "components": {},
            "id": 137193,
            "title": {
                "english": "Everyday",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "Värdag",
                "svenska": ""
            }
        },
        "health": {
            "components": {},
            "id": 137189,
            "title": {
                "english": "Health",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "Hälsa",
                "svenska": ""
            }
        },
        "capability": {
            "components": {},
            "id": 137191,
            "title": {
                "english": "Capability",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "Förmåga",
                "svenska": ""
            }
        },
        "collaboration": {
            "components": {},
            "id": 137192,
            "title": {
                "english": "Collaboration",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "Samarbetsförmåga",
                "svenska": ""
            }
        },
        "opportunity": {
            "components": {},
            "id": 137190,
            "title": {
                "english": "Opportunity",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "Möjlighet",
                "svenska": ""
            }
        }
    },
    "authors": [
        {
            "name": "Michael Rosholm"
        },
        {
            "name": "Kenneth Lykke Sørensen"
        },
        {
            "name": "Lars Skipper"
        }
    ],
    "reference_title": "BIP Indikatorer och Jobb Sannolikhet"
}