
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
    "reference_year": "2015",
    "id": 105629,
    "title": {
        "english": "Jafari et al’s Summary of How Design ‘Bears’ Values",
        "svenska": "Jafari et al’s Summary of How Design ‘Bears’ Values"
    },
    "components": {
        "utilisation": {
            "components": {
                "user-ideals": {
                    "components": {},
                    "id": 105637,
                    "title": {
                        "english": "User Ideals",
                        "svenska": ""
                    },
                    "level": 1,
                    "description": {
                        "english": "Values as ideals, and design bears the burden of approximating an ideal",
                        "svenska": ""
                    }
                },
                "user-preferences": {
                    "components": {},
                    "id": 105636,
                    "title": {
                        "english": "User Preferences",
                        "svenska": ""
                    },
                    "level": 1,
                    "description": {
                        "english": "Products bear the preferences and values of those who use them",
                        "svenska": ""
                    }
                }
            },
            "id": 105635,
            "title": {
                "english": "Utilisation",
                "svenska": ""
            },
            "level": 1,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "choice": {
            "components": {},
            "id": 105639,
            "title": {
                "english": "Choice",
                "svenska": ""
            },
            "level": 1,
            "description": {
                "english": "The capacity of designers and publics to give voice to values, to contest and argue for what should be valued; here, values are born and borne in argument",
                "svenska": ""
            }
        },
        "embodiment": {
            "components": {},
            "id": 105638,
            "title": {
                "english": "Embodiment",
                "svenska": ""
            },
            "level": 1,
            "description": {
                "english": "Products as embodying values, as value bearing material expression",
                "svenska": ""
            }
        },
        "physical": {
            "components": {},
            "id": 105634,
            "title": {
                "english": "Physical",
                "svenska": ""
            },
            "level": 1,
            "description": {
                "english": "",
                "svenska": ""
            }
        }
    },
    "authors": [
        {
            "name": "Nassim Jafari"
        },
        {
            "name": "Lisa Nathan"
        },
        {
            "name": "Ian Hargraves"
        }
    ],
    "reference_title": "Values as Hypotheses: Design, Inquiry, and the Service of Values"
}