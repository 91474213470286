
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
    "reference_year": "2022",
    "id": 0,
    "title": {
        "english": "Simplifed Representation of TPG Patient Vector",
        "svenska": "Förenklad visualisering av TPG patient data"
    },
    "components": {
        "person": {
            "components": {
				"life-situation": {
					"components": {
						"relationship-status": {
							"components": {},
							"id": 0,
							"title": {
								"english": "Relationship Status",
								"svenska": ""
							},
							"level": 0,
							"description": {
								"english": "",
								"svenska": ""
							}
						},
						"housing": {
							"components": {},
							"id": 0,
							"title": {
								"english": "Housing",
								"svenska": ""
							},
							"level": 0,
							"description": {
								"english": "",
								"svenska": ""
							}
						},
						"disabilities": {
							"components": {
								"disabilities": {
									"components": {
										"adhd": {
											"components": {},
											"id": 0,
											"title": {
												"english": "ADHD",
												"svenska": ""
											},
											"level": 0,
											"description": {
												"english": "",
												"svenska": ""
											}
										},
									},
									"id": 0,
									"title": {
										"english": "Learning Disabilities",
										"svenska": ""
									},
									"level": 0,
									"description": {
										"english": "",
										"svenska": ""
									}
								},
							},
							"id": 0,
							"title": {
								"english": "Disabilities",
								"svenska": ""
							},
							"level": 0,
							"description": {
								"english": "",
								"svenska": ""
							}
						},
						"financial-situation": {
							"components": {},
							"id": 0,
							"title": {
								"english": "Financial Situation",
								"svenska": ""
							},
							"level": 0,
							"description": {
								"english": "",
								"svenska": ""
							}
						},
						"employment-situation": {
							"components": {},
							"id": 0,
							"title": {
								"english": "Employment Situation",
								"svenska": ""
							},
							"level": 0,
							"description": {
								"english": "",
								"svenska": ""
							}
						},
						"rehab-situation": {
							"components": {},
							"id": 0,
							"title": {
								"english": "Rehabilitation Situation",
								"svenska": ""
							},
							"level": 0,
							"description": {
								"english": "",
								"svenska": ""
							}
						},
					},
					"id": 0,
					"title": {
						"english": "Life Situation",
						"svenska": ""
					},
					"level": 0,
					"description": {
						"english": "",
						"svenska": ""
					}
				},
				"health-situation": {
					"components": {
						"physical-health": {
							"components": {
								"physical-activity": {
									"components": {},
									"id": 0,
									"title": {
										"english": "Physical Activity",
										"svenska": ""
									},
									"level": 0,
									"description": {
										"english": "",
										"svenska": ""
									}
								},
								"sleep": {
									"components": {},
									"id": 0,
									"title": {
										"english": "Sleep",
										"svenska": ""
									},
									"level": 0,
									"description": {
										"english": "",
										"svenska": ""
									}
								},
							},
							"id": 0,
							"title": {
								"english": "Physical Health",
								"svenska": ""
							},
							"level": 0,
							"description": {
								"english": "",
								"svenska": ""
							}
						},
						"mental-health": {
							"components": {
								"psycho-health": {
									"components": {},
									"id": 0,
									"title": {
										"english": "Psychological Health",
										"svenska": ""
									},
									"level": 0,
									"description": {
										"english": "",
										"svenska": ""
									}
								},
								"anxiety": {
									"components": {},
									"id": 0,
									"title": {
										"english": "Anxiety",
										"svenska": ""
									},
									"level": 0,
									"description": {
										"english": "",
										"svenska": ""
									}
								},
								"stress": {
									"components": {},
									"id": 0,
									"title": {
										"english": "Stress",
										"svenska": ""
									},
									"level": 0,
									"description": {
										"english": "",
										"svenska": ""
									}
								},
								"depression": {
									"components": {},
									"id": 0,
									"title": {
										"english": "Depression",
										"svenska": ""
									},
									"level": 0,
									"description": {
										"english": "",
										"svenska": ""
									}
								},
								"self-reflection": {
									"components": {},
									"id": 0,
									"title": {
										"english": "Self Reflective Capability",
										"svenska": ""
									},
									"level": 0,
									"description": {
										"english": "",
										"svenska": ""
									}
								},
								"resilence": {
									"components": {},
									"id": 0,
									"title": {
										"english": "Resilience",
										"svenska": ""
									},
									"level": 0,
									"description": {
										"english": "",
										"svenska": ""
									}
								},
								"autonomy": {
									"components": {},
									"id": 0,
									"title": {
										"english": "Autonomy",
										"svenska": ""
									},
									"level": 0,
									"description": {
										"english": "",
										"svenska": ""
									}
								},
								"creativity": {
									"components": {},
									"id": 0,
									"title": {
										"english": "Creativity",
										"svenska": ""
									},
									"level": 0,
									"description": {
										"english": "",
										"svenska": ""
									}
								},
								"time-management": {
									"components": {},
									"id": 0,
									"title": {
										"english": "Time Management",
										"svenska": ""
									},
									"level": 0,
									"description": {
										"english": "",
										"svenska": ""
									}
								},
								"empowerment": {
									"components": {},
									"id": 0,
									"title": {
										"english": "Empowerment",
										"svenska": ""
									},
									"level": 0,
									"description": {
										"english": "",
										"svenska": ""
									}
								},
								"resourcefulness": {
									"components": {},
									"id": 0,
									"title": {
										"english": "Resourcefulness",
										"svenska": ""
									},
									"level": 0,
									"description": {
										"english": "",
										"svenska": ""
									}
								},
							},
							"id": 0,
							"title": {
								"english": "Psychological Health",
								"svenska": ""
							},
							"level": 0,
							"description": {
								"english": "",
								"svenska": ""
							}
						},
						"social-health": {
							"components": {
								"collaboration": {
									"components": {},
									"id": 0,
									"title": {
										"english": "Collaboration",
										"svenska": ""
									},
									"level": 0,
									"description": {
										"english": "",
										"svenska": ""
									}
								},
								"loneliness": {
									"components": {},
									"id": 0,
									"title": {
										"english": "Loneliness",
										"svenska": ""
									},
									"level": 0,
									"description": {
										"english": "",
										"svenska": ""
									}
								},
								"social-activity": {
									"components": {},
									"id": 0,
									"title": {
										"english": "Social Activity",
										"svenska": ""
									},
									"level": 0,
									"description": {
										"english": "",
										"svenska": ""
									}
								},
								
							},
							"id": 0,
							"title": {
								"english": "Social Health",
								"svenska": ""
							},
							"level": 0,
							"description": {
								"english": "",
								"svenska": ""
							}
						},
					},
					"id": 0,
					"title": {
						"english": "Health Situation",
						"svenska": ""
					},
					"level": 0,
					"description": {
						"english": "",
						"svenska": ""
					}
				},
			},
            "id": 0,
            "title": {
                "english": "Person",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        },
        "sociocultural-context": {
            "components": {},
            "id": 0,
            "title": {
                "english": "Sociocultural Context",
                "svenska": ""
            },
            "level": 0,
            "description": {
                "english": "",
                "svenska": ""
            }
        }
    },
    "authors": [
        {
            "name": "Fergus Bisset"
        }
    ],
    "reference_title": ""
}